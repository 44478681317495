<ng-template #purchaseCredits>
  <ng-container *ngIf="inEditMode">
    <div class="d-flex mb-2">
      <label class="fs-6 col-sm-4 col-form-label fw-bold fs-6 editor-label">Restrict to License States:</label>
      <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid" style="margin-top: 8px;">
        <input class="form-check-input h-20px w-30px" type="checkbox" id="restrictStatesToLicenseStates"
          [ngModelOptions]="{standalone: true}" [(ngModel)]="step.restrictStatesToLicenseStates" />
        <label class="form-check-label" for="restrictStatesToLicenseStates">
          {{step.restrictStatesToLicenseStates ? "Yes" : "No"}}
        </label>
      </div>
    </div>
    <div class="d-flex mb-2">
      <label class="fs-6 col-sm-4 col-form-label fw-bold fs-6 editor-label">Require User to Enter Zipcode:</label>
      <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid" style="margin-top: 8px;">
        <input class="form-check-input h-20px w-30px" type="checkbox" id="requireUserToEnterZipcode"
          [ngModelOptions]="{standalone: true}" [(ngModel)]="step.requireUserToEnterZipcode" />
        <label class="form-check-label" for="requireUserToEnterZipcode">
          {{step.requireUserToEnterZipcode ? "Yes" : "No"}}
        </label>
      </div>
    </div>
    <div class="d-flex mb-2">
      <label class="fs-6 col-sm-4 col-form-label fw-bold fs-6 editor-label">Auto-Select 'Looking to Get Pre-Approved':</label>
      <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid" style="margin-top: 8px;">
        <input class="form-check-input h-20px w-30px" type="checkbox" id="autoSelectLookingToGetPreApproved"
          [ngModelOptions]="{standalone: true}" [(ngModel)]="step.autoSelectLookingToGetPreApproved" />
        <label class="form-check-label" for="autoSelectLookingToGetPreApproved">
          {{step.autoSelectLookingToGetPreApproved ? "Yes" : "No"}}
        </label>
      </div>
    </div>
    <div class="d-flex mb-2">
      <label class="fs-6 col-sm-4 col-form-label fw-bold fs-6 editor-label">Auto-Select TBD for Subject Property Address':</label>
      <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid" style="margin-top: 8px;">
        <input class="form-check-input h-20px w-30px" type="checkbox" id="autoSelectTbdForSubjectPropertyAddress"
          [ngModelOptions]="{standalone: true}" [(ngModel)]="step.autoSelectTbdForSubjectPropertyAddress"
          (ngModelChange)="onAutoSelectTbdForSubjectPropertyAddressChanged()"/>
        <label class="form-check-label" for="autoSelectTbdForSubjectPropertyAddress">
          {{step.autoSelectTbdForSubjectPropertyAddress ? "Yes" : "No"}}
        </label>
      </div>
    </div>
    <div class="d-flex mb-2">
      <label class="fs-6 col-sm-4 col-form-label fw-bold fs-6 editor-label">Require User to Enter Zipcode:</label>
      <div class="col-sm-2 form-check form-switch form-check-custom form-check-solid" style="margin-top: 8px;">
        <input class="form-check-input h-20px w-30px" type="checkbox" id="requireUserToEnterZipcode"
          [ngModelOptions]="{standalone: true}" [(ngModel)]="step.requireUserToEnterZipcode" />
        <label class="form-check-label" for="requireUserToEnterZipcode">
          {{step.requireUserToEnterZipcode ? "Yes" : "No"}}
        </label>
      </div>
    </div>
  </ng-container>
  <form #purchaseCreditsForm="ngForm" novalidate id="purchaseCreditsForm" name="purchaseCreditsForm">
    <div class="fv-row" *ngIf="!step.autoSelectLookingToGetPreApproved">
      <div class="row mb-10">
        <div class="col">
          <label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
            [ngClass]="{'active': hasPurchaseAgreement === true}">
            <input type="radio" class="btn-check" name="yes" id="yes" (click)="onIsInPurchaseAggrementSelected()" />
            <span *ngIf="!inEditMode" class="fw-bolder fs-4">{{step.underContractActionLabel || 'Under Contract'}}</span>
            <input *ngIf="inEditMode" name="underContractActionLabel" type="text"
              class="form-control  form-control-solid fs-2 fw-bolder" [(ngModel)]="step.underContractActionLabel" />
          </label>
        </div>
        <div class="col">
          <label class="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
            [ngClass]="{'active': hasPurchaseAgreement === false}">
            <input type="radio" class="btn-check" id="no" name="no" (click)="onLookingForPreApprovalSelected()" />
            <span *ngIf="!inEditMode" class="fw-bolder fs-4">{{step.lookingToGetPreApprovedActionLabel || 'Looking to Get Pre-approved'}}</span>
            <input *ngIf="inEditMode" name="lookingToGetPreApprovedActionLabel" type="text"
              class="form-control  form-control-solid fs-2 fw-bolder"
              [(ngModel)]="step.lookingToGetPreApprovedActionLabel" />
          </label>
        </div>
      </div>
    </div>
    <ng-container *ngIf="hasPurchaseAgreement">
      <div class="row">
        <label class="form-label">What is the property address?</label>
        <address 
          #propertyAddress 
          [availableStates]="availableStates" 
          [inEditMode]="inEditMode"
          [address]="subjectPropertyAddress!"
          [fieldsToConfig]="step.fieldConfig" 
          [fieldConfigSettings]="fieldConfigSettingsForAddress"
          [zipCodeLookupEnabled]="true">
        </address>
      </div>
      <div class="row" *ngIf="subjectProperty">
        <div class="col-md-4 fv-row mt-1" *ngIf="!isHidden('subjectProperty.propertyWillBe')">
          <label class="form-label"
            [ngClass]="{'required': isRequired('subjectProperty.propertyWillBe')}">{{getLabel('subjectProperty.propertyWillBe',
            'Property Will Be')}}</label>
          <select class="form-select form-select-solid" data-control="select2" name="occupancy" #occupancy="ngModel"
            [(ngModel)]="subjectProperty.propertyWillBe"
            [ngClass]="{'is-invalid' : occupancy && occupancy.touched && occupancy.invalid}"
            [required]="isRequired('subjectProperty.propertyWillBe')">
            <option [ngValue]="null">Select one...</option>
            <option [ngValue]="occupancyType.value" *ngFor="let occupancyType of occupancyTypes">
              {{occupancyType.name}}
            </option>
          </select>
        </div>
        <div class="col-md-2" *ngIf="!isHidden('subjectProperty.yearBuilt') && !isSubjectPropertyAddressTbd">
          <label class="fs-6 fw-bold form-label mb-2" [ngClass]="{'required': isRequired('subjectProperty.yearBuilt')}">
            <span>{{getLabel('subjectProperty.yearBuilt', 'Year Built')}}</span>
          </label>
          <div class="position-relative">
            <input type="text" numeric input inputmode="numeric" class="form-control form-control-solid"
              [(ngModel)]="subjectProperty.yearBuilt" name="yearBuilt"
              [required]="isRequired('subjectProperty.yearBuilt')"
              [ngClass]="{'is-invalid' : yearBuilt && yearBuilt.touched && yearBuilt.invalid}" #yearBuilt="ngModel" />
          </div>
        </div>
        <div class="col-md-2 fv-row mt-1" *ngIf="!isHidden('subjectProperty.noOfUnits')">
          <label class="form-label" [ngClass]="{'required': isRequired('subjectProperty.noOfUnits')}">
            {{getLabel('subjectProperty.noOfUnits', 'Number of Units')}}
          </label>
          <div class="position-relative d-flex align-items-center">
            <input class="form-control form-control-solid" name="noOfUnits" id="noOfUnits" numeric
              [allowNegative]="false" [required]="isRequired('subjectProperty.noOfUnits')"
              [(ngModel)]="subjectProperty.noOfUnits" type="number"
              [ngClass]="{'is-invalid' : noOfUnits && noOfUnits.touched && noOfUnits.invalid}" inputmode="numeric"
              #noOfUnits="ngModel" />
          </div>
        </div>
      </div>
      <div class="row mt-7" *ngIf="!isHidden('mortgageApplication.extension.purchaseAgreementContractSignedDate')">
        <div class="col-md-4 col-sm-12 text-dark mb-2 fs-6 mt-1"
          [ngClass]="{'required': isRequired('mortgageApplication.extension.purchaseAgreementContractSignedDate')}"
          style="padding-top: 4px;">
          {{getLabel('mortgageApplication.extension.purchaseAgreementContractSignedDate', 'When was the contract
          signed?')}}
        </div>
        <div class="col-md-2 col-sm-12 mb-3">
          <input class="form-control form-control-solid" matInput mask="M0/d0/0000" [showMaskTyped]="true"
            inputmode="numeric" [specialCharacters]="[ '/' ]" [dropSpecialCharacters]="false" datePicker
            #dateOfPurchaseAggreement="ngModel" [(ngModel)]="purchaseAggreementDate" placeholder=""
            name="purchaseAggreementDate" ngControl="purchaseAggreementDate" id="purchaseAggreementDate"
            [ngClass]="{'is-invalid' : dateOfPurchaseAggreement && dateOfPurchaseAggreement.touched && dateOfPurchaseAggreement.invalid}"
            [required]="isRequired('mortgageApplication.extension.purchaseAgreementContractSignedDate')" />
        </div>
      </div>

      <div class="row mt-2" *ngIf="!isHidden('mortgageApplication.extension.purchaseAgreementExpectedCOEDate')">
        <div class="col-md-4 col-sm-12 text-dark mb-2 fs-6 mt-1"
          [ngClass]="{'required': isRequired('mortgageApplication.extension.purchaseAgreementExpectedCOEDate')}"
          style="padding-top: 4px;">{{getLabel('mortgageApplication.extension.purchaseAgreementExpectedCOEDate', 'When
          is the expected close of escrow?')}}
        </div>
        <div class="col-md-2 col-sm-12 mb-3">
          <input class="form-control form-control-solid" matInput mask="M0/d0/0000" [showMaskTyped]="true"
            inputmode="numeric" [specialCharacters]="[ '/' ]" inputmode="numeric" [dropSpecialCharacters]="false"
            datePicker #dateOfCoe="ngModel" [(ngModel)]="expectedCloseOfEscrowDate" placeholder=""
            name="expectedCloseOfEscrowDate" ngControl="expectedCloseOfEscrowDate" id="expectedCloseOfEscrowDate"
            [ngClass]="{'is-invalid' : dateOfCoe && dateOfCoe.touched && dateOfCoe.invalid}"
            [required]="isRequired('mortgageApplication.extension.purchaseAgreementExpectedCOEDate')" />
        </div>
      </div>

      <div class="row mt-2"
        *ngIf="!isHidden('mortgageApplication.transactionDetail.purchaseCredits.earnestMoneyDeposit')">
        <div class="col-md-4 col-sm-12 text-dark mb-2 fs-6 mt-1"
          [ngClass]="{'required': isRequired('mortgageApplication.transactionDetail.purchaseCredits.earnestMoneyDeposit')}"
          style="padding-top: 4px;">
          {{getLabel('mortgageApplication.transactionDetail.purchaseCredits.earnestMoneyDeposit', 'How much is the earnest money deposit?')}}
        </div>
        <div class="col-md-2 col-sm-12 mb-3">
          <input style="min-width: 85px" type="text" class="form-control form-control-solid" mask="separator"
            inputmode="decimal" thousandSeparator="," prefix="$" [(ngModel)]="earnestMoneyDeposit" placeholder=""
            id="emd" name="emd" #emdAmount="ngModel"
            [required]="isRequired('mortgageApplication.transactionDetail.purchaseCredits.earnestMoneyDeposit')"
            (blur)="onSellerCreditsChanged()"
            [ngClass]="{'is-invalid' : emdAmount && emdAmount.touched && emdAmount.invalid}" />
          <!-- <div class="invalid-feedback">Please enter an amount for earnest money deposit.</div> -->
        </div>
      </div>

      <div class="row mb-5 mt-6 rounded" [ngClass]="{
            'is-invalid' : haveSellerCreditsQuestion && haveSellerCreditsQuestion.touched && haveSellerCreditsQuestion.invalid,
            'border' : haveSellerCreditsQuestion && haveSellerCreditsQuestion.touched && haveSellerCreditsQuestion.invalid,
            'p-4' : haveSellerCreditsQuestion && haveSellerCreditsQuestion.touched && haveSellerCreditsQuestion.invalid,
            'border-danger' : haveSellerCreditsQuestion && haveSellerCreditsQuestion.touched && haveSellerCreditsQuestion.invalid}">
        <div class="col-md-4 col-sm-12 text-dark mb-3 fs-6 required" style="padding-top: 4px;">Are there any seller
          credits?
        </div>
        <div class="col-md-2 col-sm-12 mb-2 mt-1">
          <div class="form-check form-check-custom form-check-solid" style="display: inline-flex;">
            <input class="form-check-input" name="haveSellerCredits" required [value]="true" type="radio"
              id="haveSellerCredits" [(ngModel)]="haveSellerCredits" #haveSellerCreditsQuestion="ngModel"
              (ngModelChange)="onHaveSellerCreditsQuestionAnswered()" />
            <label style="padding-right: 6px;" class="form-check-label fs-6" for="haveSellerCredits">Yes</label>
          </div>
          <div class="form-check form-check-custom form-check-solid ms-2" style="display: inline-flex;">
            <input class="form-check-input" name="haveNotSellerCredits" required [value]="false" type="radio"
              id="haveNotSellerCredits" [(ngModel)]="haveSellerCredits" #haveSellerCreditsQuestion="ngModel"
              (ngModelChange)="onHaveSellerCreditsQuestionAnswered()" />
            <label style="padding-right: 6px;" class="form-check-label fs-6" for="haveNotSellerCredits">No</label>
          </div>
        </div>
      </div>

      <div class="invalid-feedback">Please answer the question above, so that we can help you better with your
        application.</div>
      <div class="row"
        *ngIf="haveSellerCredits && !isHidden('mortgageApplication.transactionDetail.purchaseCredits.sellerCredits')">
        <div class="col-md-4 col-sm-12 text-dark mb-2 fs-6 mt-1" style="padding-top: 4px;"
          [ngClass]="{'required': haveSellerCredits && isRequired('mortgageApplication.transactionDetail.purchaseCredits.sellerCredits')}">
          {{getLabel('mortgageApplication.transactionDetail.purchaseCredits.sellerCredits', 'What is the amount?')}}
        </div>
        <div class="col-md-2 col-sm-12 mb-3">
          <input style="min-width: 85px" type="text" class="form-control form-control-solid" mask="separator"
            inputmode="decimal" thousandSeparator="," prefix="$" [(ngModel)]="sellerCredits" placeholder=""
            id="sellerCredits" name="sellerCredits" #sellerCreditsAmount="ngModel"
            [required]="haveSellerCredits && isRequired('mortgageApplication.transactionDetail.purchaseCredits.sellerCredits')"
            (blur)="onSellerCreditsChanged()"
            [ngClass]="{'is-invalid' : sellerCreditsAmount && sellerCreditsAmount.touched && sellerCreditsAmount.invalid}" />
          <!-- <div class="invalid-feedback">Please enter an amount for seller credits.</div> -->
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!hasPurchaseAgreement">
      <label class="form-label">What is the property address?</label>
      <div class="row mt-2">
        <address
          [address]="subjectPropertyAddress!"
          [availableStates]="availableStates"
          [canSetTbd]="true"
          [zipCodeLookupEnabled]="true"
          [inEditMode]="inEditMode"
          [requireUserToEnterZipCodeWhenTbd]="step.requireUserToEnterZipcode"
          [fieldsToConfig]="step.fieldConfig" [fieldConfigSettings]="fieldConfigSettingsForAddress"
          [addressTbdLabel]="step.subjectPropertyAddressTbdLabel" 
          (addressTbdLabelChanged)="onSubjectPropertyAddressTbdLabelChanged($event)"
          (addressIsTbdChanged)="onSubjectPropertyAddressIsTbdChanged($event)"
          #propertyAddress>
        </address>
      </div>
      <div class="row">
        <div class="col-md-4" *ngIf="!isHidden('subjectProperty.propertyWillBe')">
          <label class="form-label"
            [ngClass]="{'required': isRequired('subjectProperty.propertyWillBe')}">{{getLabel('subjectProperty.propertyWillBe',
            'Property Will Be')}}</label>
          <select class="form-select form-select-solid" data-control="select2" name="occupancy" #occupancy="ngModel"
            [(ngModel)]="subjectProperty.propertyWillBe"
            [ngClass]="{'is-invalid' : occupancy && occupancy.touched && occupancy.invalid}"
            [required]="isRequired('subjectProperty.propertyWillBe')">
            <option [ngValue]="null">Select one...</option>
            <option [ngValue]="occupancyType.value" *ngFor="let occupancyType of occupancyTypes">
              {{occupancyType.name}}
            </option>
          </select>
        </div>
        <div class="col-md-4" *ngIf="!isHidden('subjectProperty.yearBuilt') && !isSubjectPropertyAddressTbd">
          <label class="fs-6 fw-bold form-label mb-2" [ngClass]="{'required': isRequired('subjectProperty.yearBuilt')}">
            <span>{{getLabel('subjectProperty.yearBuilt', 'Year Built')}}</span>
          </label>
          <div class="position-relative">
            <input type="text" numeric class="form-control form-control-solid" inputmode="numeric"
              [(ngModel)]="subjectProperty.yearBuilt" name="yearBuilt"
              [required]="isRequired('subjectProperty.yearBuilt')"
              [ngClass]="{'is-invalid' : yearBuilt && yearBuilt.touched && yearBuilt.invalid}" #yearBuilt="ngModel" />
          </div>
        </div>
      </div>
    </ng-container>
  </form>
</ng-template>

<wizard-step-template [stepMainTemplate]="purchaseCredits" [step]="step" [stepComponent]="this"></wizard-step-template>
