<div class="post d-flex flex-column-fluid">
  <!--begin::Container-->
  <div class="container">
    <!--begin::Card-->
    <div class="card">
      <!--begin::Card body-->
      <div class="card-body p-0">
        <!--begin::Heading-->
        <div class="card-px text-center py-20 my-10">
          <!--begin::Title-->
          <h2 class="fs-2x fw-bolder mb-10">Employment Modal Example</h2>
          <!--end::Title-->
          <!--begin::Description-->
          <p class="text-gray-400 fs-5 fw-bold mb-13">Click on the below buttons to launch
          <br />a new address example.</p>
          <!--end::Description-->
          <!--begin::Action-->
          <a href="#" class="btn btn-primary er fs-6 px-8 py-4" data-bs-toggle="modal" data-bs-target="#kt_modal_new_employment">Add Employment</a>
          <!--end::Action-->
        </div>
        <!--end::Heading-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Card-->
    <!--begin::Modal - New Address-->
    <employment-dialog></employment-dialog>
    <!--end::Modal - New Address-->
  </div>
  <!--end::Container-->
</div>
