<ng-template #checkLists>
  <ng-container *ngIf="inEditMode && !isLoadingChecklists">
    <h5 class="mb-5">Please, select checklists that you'd like to apply to this flow:</h5>
    <checklist-picker [checklists]="checklists" [selectedChecklistIds]="selectedChecklistIds"
      (selectedChecklistsChanged)="onSelectedChecklistsChanged($event)">
    </checklist-picker>
  </ng-container>
  <ng-container *ngIf="!isLoadingChecklists && !isEditEnabled && selectedChecklistIds && selectedChecklistIds.length">
    <div class="col-md-12 fv-row mt-2 mb-2" style="min-height: 180px;">
      <ng-container *ngFor="let borrower of borrowersToRunTroughTheChecklist; let i = index">
        <borrower-checklists
          [hidden]="currentBorrowerIndex !== i"
          #borrowerChecklists
          [applicationId]="mortgage.applicationId"
          [borrower]="borrower"
          [selectedChecklistIds]="selectedChecklistIds"
          [questionMetaData]="questionMetaData"
          [questionsByChecklist]="questionsByChecklist"
          [isPrimaryBorrower]="currentBorrower.borrowerId === borrower.borrowerId"
          (allQuestionsAreTriggerOnly)="onBorrowerNotifiedThatAllQuestionsAreTriggerOnly($event)">
        </borrower-checklists>
      </ng-container>
    </div>

    <div class="d-flex flex-stack pt-5 pb-5">
      <div class="mr-2">
        <button *ngIf="showPreviousButton" type="button" class="btn btn-lg btn-light-primary me-3"
          (click)="onPreviousQuestionClicked()">
          <span class="svg-icon svg-icon-4 me-1">
            <duotone-navigation-left-svg-icon></duotone-navigation-left-svg-icon>
          </span>
          Back
        </button>
      </div>
      <div>
        <button *ngIf="showNextButton" type="button" class="btn btn-lg btn-primary"
          (click)="onNextQuestionClicked()">Next
          <span *ngIf="!isSavingAnswer" class="svg-icon svg-icon-4 ms-1 me-0">
            <duotone-navigation-right-svg-icon></duotone-navigation-right-svg-icon>
          </span>
          <span *ngIf="isSavingAnswer" class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>

<wizard-step-template [showSpinnerOnMainContent]="showSpinnerOnMainContent" [stepMainTemplate]="checkLists" [step]="step" [stepComponent]="this"></wizard-step-template>
