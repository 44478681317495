
<div class="d-flex flex-stack justify-content-center pt-5 pb-5 px-20">
  <div class="mr-2">
    <button type="button" class="btn btn-lg btn-light-primary me-3" *ngIf="backVisible" [disabled]="!backEnabled" (click)="onBackClicked()">
      <span class="svg-icon svg-icon-4 me-1">
        <duotone-navigation-left-svg-icon></duotone-navigation-left-svg-icon>
      </span>
      Back
    </button>
  </div>
  <div *ngIf="nextVisible">
    <button type="button" class="btn btn-lg btn-primary" [disabled]="!nextEnabled || spinnerVisible" (click)="onNextClicked()">{{nextButtonText}}
      <span *ngIf="!spinnerVisible" class="svg-icon svg-icon-4 ms-1 me-0">
        <duotone-navigation-right-svg-icon></duotone-navigation-right-svg-icon>
      </span>
      <span *ngIf="spinnerVisible" class="spinner-border spinner-border-sm align-middle ms-2"></span>
    </button>
  </div>
</div>
