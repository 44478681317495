<div class="row">
  <div class="p-2 px-5 col-md-3">
    <div class="d-flex align-items-center mb-2">
      <div class="symbol symbol-40px symbol-lg-80px symbol-fixed position-relative me-4">
        <span class="svg-icon svg-icon-2x svg-icon-primary">
          <duotone-home-svg-icon></duotone-home-svg-icon>
        </span>
      </div>
      <a
        (click)="onSubjectPropertyDetailsClicked()"
        href="javascript:void(0)"
        class="text-gray-800 text-hover-primary fs-4 fw-bolder me-1">
          Subject Property
        </a>
    </div>
    <div class="fw-bold mb-4 pe-2">
      <a (click)="onSubjectPropertyDetailsClicked()"
        href="javascript:void(0)" class="align-items-center text-gray-400 text-hover-primary me-5 mb-2" style="display: block;">
        <span class="svg-icon svg-icon-4 me-1">
          <duotone-map-marker-svg-icon></duotone-map-marker-svg-icon>
        </span>
        {{subjectProperty.address1}}
      </a>
    </div>
  </div>
  <div class="p-2 px-5 col-md-3" style="min-height: 45px;">
    <div class="mb-3">
      <div class="text-gray-400 fw-bold">Type: <span class="text-gray-800 fw-bold">{{propertyType}}</span></div>
    </div>
    <div class="mb-3">
      <div class="text-gray-400 fw-bold">Will be: <span class="text-gray-800 fw-bold">{{propertyWillBe}}</span></div>
    </div>
  </div>
  <div class="p-2 px-5 col-md-3" style="min-height: 45px;">
    <div class="mb-3">
      <div class="text-gray-400 fw-bold">Value: <span class="text-gray-800 fw-bold">{{subjectProperty.presentValue | currency}}</span></div>
    </div>
    <div class="mb-3">
      <div class="text-gray-400 fw-bold">Year Built: <span class="text-gray-800 fw-bold">{{subjectProperty.yearBuilt}}</span></div>
    </div>
  </div>
</div>
