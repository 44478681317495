import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'projects/shared/modal/modal.service';
import { SimpleIncomeDialogComponent } from '../wizard-steps/simple-income-dialog/simple-income-dialog.component';
import { IncomeItem } from '../../models/income-item-model';
import { Employment } from '../../models/employment-model';
import { Income } from '../../models/income-model';
import { EmploymentDialogComponent } from '../wizard-steps/employment-dialog/employment-dialog.component';
import { Borrower } from '../../models/borrower-model';
import * as _ from 'lodash';
import { TypeOfIncome } from '../../models/enums/type-of-income.enum';
import { EnumsService } from '../../services/enums.service';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { EmploymentTypeEnum } from '../../models/mortgage.model';
import { FieldConfigUtils } from '../../models/wizard/config/field-config-utils';

@Component({
  selector: 'borrower-income',
  templateUrl: 'borrower-income.component.html',
})
export class BorrowerIncomeComponent implements OnInit, AfterViewInit {
  private modalOptions: NgbModalOptions = {
    size: 'lg',
    backdrop: 'static',
    container: '#income-main',
  };

  @Input() borrower!: Borrower;

  @Input()
  fieldConfig: any;

  @Output()
  employmentChanged: EventEmitter<any> = new EventEmitter<any>();

  incomes: IncomeItem[] = [];

  private _incomeTypes: EnumerationItem[] = [];

  get totalIncome(): number {
    let sum: number = 0;
    this.incomes.forEach((income) => {
      sum = sum + Number(income.monthlyPay);
    });
    return sum;
  }

  isValid(): boolean {
    let result: boolean = true;
    this.incomes.forEach((income) => {
      if (!income.isValid) {
        result = false;
      }
    });
    return result;
  }

  private _employmentBasedIncomeTypes: TypeOfIncome[] = [
    TypeOfIncome.Base,
    TypeOfIncome.Overtime,
    TypeOfIncome.Bonus,
    TypeOfIncome.Commissions,
    TypeOfIncome.ContractBasis,
    TypeOfIncome.MilitaryBasePay,
    TypeOfIncome.SelfEmploymentIncome
  ]

  private _nonEmploymentBasedIncomeTypes: TypeOfIncome[] = [];

  constructor(private readonly _modalService: ModalService,
    private readonly _enumsService: EnumsService) {
  }

  ngOnInit() {
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this._incomeTypes = enums['IncomeType'];
      this._nonEmploymentBasedIncomeTypes = this._incomeTypes
        .filter(it => !this._employmentBasedIncomeTypes.includes(it.value)).map(it => it.value);
      this.populateIncomeItems(this.borrower);
    });
  }

  ngAfterViewInit() { }

  refresh = () => {
    this.populateIncomeItems(this.borrower);
  };

  onIncomeDeleted = (income: IncomeItem) => {
    if (
      income.type == TypeOfIncome.Base ||
      income.type == TypeOfIncome.ContractBasis ||
      income.type == TypeOfIncome.MilitaryBasePay ||
      income.type == TypeOfIncome.SelfEmploymentIncome ||
      income.type === undefined
    ) {
      this.deleteIncomeFromEmployment(income);
      this.employmentChanged.emit();
    } else {
      this.deleteIncomeFromNonEmploymentIncomes(income);
    }
  };

  onIncomeEditClicked = (income: IncomeItem) => {
    const incomeItemToEdit = this.incomes.find((i) => i.guid === income.guid);
    if (!incomeItemToEdit) {
      return;
    }

    if (this._employmentBasedIncomeTypes.includes(incomeItemToEdit.type)) {
      this.handleEmploymentIncomeClick(incomeItemToEdit);
    } else if (this._nonEmploymentBasedIncomeTypes.includes(incomeItemToEdit.type)) {
      this.handleNonEmploymentIncomeClick(incomeItemToEdit);
    }
  };

  private handleEmploymentIncomeClick = (incomeItemToEdit: IncomeItem) => {
    const employmentToEdit = this.borrower.employments.find(
      (e) => e.guid === incomeItemToEdit.guid
    );
    if (employmentToEdit) {
      employmentToEdit.monthlyIncome = incomeItemToEdit.monthlyPay;
      if (employmentToEdit.incomes && employmentToEdit.incomes.length) {
        employmentToEdit.businessType = employmentToEdit.incomes[0].businessType;
      }
      this.showEmploymentIncomeDialog(incomeItemToEdit.type, _.cloneDeep(employmentToEdit)
      );
    }
  }

  private handleNonEmploymentIncomeClick = (incomeItemToEdit: IncomeItem) => {
    const incomeToEdit = this.borrower.nonEmploymentIncomes.find(
      (e) => e.guid === incomeItemToEdit.guid
    );
    if (incomeToEdit) {
      const header = this.getHeaderForNonEmploymentIncome(incomeToEdit.typeOfIncome);
      this.showNonEmploymentIncomeDialog(header, "Monthly Income", incomeItemToEdit.type, incomeToEdit);
    }
  }

  private deleteIncomeFromEmployment(income: IncomeItem) {
    const index = this.incomes.indexOf(income);
    const employments = this.borrower.employments;
    if (index >= 0) {
      this.incomes.splice(index, 1);
      const employmentToDelete = employments.find(
        (e) => e.guid === income.guid
      );
      if (employmentToDelete) {
        const index = employments.indexOf(employmentToDelete);
        if (index >= 0) {
          employments.splice(index, 1);
        }
      }
    }
  }

  private deleteIncomeFromNonEmploymentIncomes(income: IncomeItem) {
    const index = this.incomes.indexOf(income);
    const nonEmploymentIncomes = this.borrower.nonEmploymentIncomes;
    if (index >= 0) {
      this.incomes.splice(index, 1);
      const incomeToDelete = nonEmploymentIncomes.find(
        (i) => i.guid === income.guid
      );
      if (incomeToDelete) {
        const index = nonEmploymentIncomes.indexOf(incomeToDelete);
        if (index >= 0) {
          nonEmploymentIncomes.splice(index, 1);
        }
      }
    }
  }

  private isEmploymentValid(
    typeOfIncome: TypeOfIncome | undefined,
    employment: Employment
  ): boolean {
    if (FieldConfigUtils.isRequied(this.fieldConfig, 'employment.employer') &&
      (!employment.employer || employment.employer.length == 0)) {
      return false;
    }

    if (FieldConfigUtils.isRequied(this.fieldConfig, 'employment.employmentType') && (employment.employmentType == null)) {
      return false;
    }

    if (FieldConfigUtils.isRequied(this.fieldConfig, 'employment.startDate') && !employment.startDate) {
      return false;
    }

    if(!(employment.employer && employment.employer == "Retired" && employment.employmentType == EmploymentTypeEnum.CurrentEmployer)){

      if (FieldConfigUtils.isRequied(this.fieldConfig, 'employment.position') && (!employment.position || employment.position.length == 0)) {
        return false;
      }
      if (employment.isPrimary == null) {
        return false;
      }

      if (employment.incomes == null || employment.incomes.length == 0) {
        return false;
      }
      if (FieldConfigUtils.isRequied(this.fieldConfig, 'employment.yearsInLineOfWork') &&
        (employment.yearsInLineOfWork == null ||
        employment.yearsInLineOfWork <= 0)
      ) {
        return false;
      }

      if (typeOfIncome && typeOfIncome !== TypeOfIncome.ContractBasis) {
        if (
          !employment.address.address1 ||
          employment.address.address1.length === 0
        ) {
          return false;
        }
        if (!employment.address.city || employment.address.city.length === 0) {
          return false;
        }
        if (!employment.address.state || employment.address.state.length === 0) {
          return false;
        }
        if (
          !employment.address.zipCode ||
          employment.address.zipCode.length === 0
        ) {
          return false;
        }
      }

    }
    return true;
  }

  private isIncomeValid(income: Income): boolean {
    if (income.typeOfIncome == TypeOfIncome.OtherTypesOfIncome) {
      if (
        !income.typeOfIncomeOtherDescription ||
        income.typeOfIncomeOtherDescription.length == 0
      ) {
        return false;
      }
    }
    if (!income.monthlyIncome || income.monthlyIncome <= 0) {
      return false;
    }
    return true;
  }

  private toIncomeItem = (
    employment: Employment,
    income: Income
  ): IncomeItem => {
    const incomeItem = new IncomeItem(
      income.typeOfIncome!,
      income.monthlyIncome!,
      this.isEmploymentValid(income.typeOfIncome, employment),
      employment.position,
      employment.startDate!
    );
    incomeItem.name = employment.employer;
    incomeItem.id = employment.employmentId;
    employment.guid = incomeItem.guid;
    return incomeItem;
  };

  private typeOfIncomeDisplayText = (typeOfIncome: TypeOfIncome): string => {
    const incomeType = this._incomeTypes.find(it => it.value === typeOfIncome);
    if (incomeType) {
      return incomeType.name;
    }
    return typeOfIncome;
  };

  private toIncomeItemNonEmployment = (income: Income): IncomeItem => {
    const incomeItem = new IncomeItem(
      income.typeOfIncome!,
      income.monthlyIncome!,
      this.isIncomeValid(income)
    );
    incomeItem.name = this.typeOfIncomeDisplayText(income.typeOfIncome!);
    incomeItem.id = income.incomeId;
    if (income.typeOfIncome == TypeOfIncome.OtherTypesOfIncome) {
      incomeItem.position = income.typeOfIncomeOtherDescription;
    }
    income.guid = incomeItem.guid;
    return incomeItem;
  };

  private populateIncomeItems(borrower: Borrower) {
    this.incomes = [];
    borrower.employments.forEach((employment) => {
      // Got rid of the base salary filtering, not sure if it's the right thing to do?
      const incomes = employment.incomes.filter((i) => i);
      incomes.forEach((income) => {
        const incomeItem = this.toIncomeItem(employment, income);
        incomeItem.digitallyVerified = employment.isDigitallyVerified;
        this.incomes.push(incomeItem);
      });
    });

    borrower.nonEmploymentIncomes.forEach((nonEmploymentIncome) =>
      this.incomes.push(this.toIncomeItemNonEmployment(nonEmploymentIncome))
    );
  }

  private showEmploymentIncomeDialog(
    typeOfIncome: TypeOfIncome,
    employment?: Employment
  ) {
    const modal = this._modalService.show(
      EmploymentDialogComponent,
      this.modalOptions
    );
    modal.componentInstance.typeOfIncome = typeOfIncome;
    modal.componentInstance.fieldsToConfig = this.fieldConfig;
    if (employment) {
      modal.componentInstance.employment = employment;
    } else if (
      typeOfIncome == TypeOfIncome.ContractBasis ||
      typeOfIncome === TypeOfIncome.SelfEmploymentIncome
    ) {
      modal.componentInstance.employment.selfEmployed = true;
    }
    modal.result.then(
      (employmentAndIncomeType) => {
        this.onSaveClickedOnEmploymentModalForEdit(employmentAndIncomeType.employment, typeOfIncome);
      },
      (error) => { }
    );
  }

  private onSaveClickedOnIncomeModalForEdit = (
    income: Income,
    typeOfIncome: TypeOfIncome
  ): void => {
    const nonEmploymentIncomes = this.borrower.nonEmploymentIncomes;
    if (!nonEmploymentIncomes) {
      return;
    }
    const existingNonEmploymentIncome = nonEmploymentIncomes?.find(
      (e) => e.guid === income.guid
    );
    if (!existingNonEmploymentIncome) {
      return;
    }
    const index = nonEmploymentIncomes?.indexOf(existingNonEmploymentIncome);
    nonEmploymentIncomes[index] = income;

    var incomeItem = this.incomes.find((i) => i.guid === income.guid);
    if (incomeItem) {
      const index = this.incomes.indexOf(incomeItem);
      const updatedIncomeItem = this.toIncomeItemNonEmployment(income);
      this.incomes[index] = updatedIncomeItem;
    }
  };

  private onSaveClickedOnEmploymentModalForEdit = (
    employment: Employment,
    typeOfIncome: TypeOfIncome
  ): void => {
    const employments = this.borrower.employments;
    if (employments) {
      const existingEmployment = employments?.find(
        (e) => e.guid === employment.guid
      );
      if (existingEmployment) {
        const index = employments?.indexOf(existingEmployment);
        const monthlyIncome = (typeOfIncome === TypeOfIncome.SelfEmploymentIncome)
          ? employment.selfEmploymentMonthlyIncomeOrLoss : employment.monthlyIncome;
        employments[index] = employment;
        employments[index].incomes[0].monthlyIncome = monthlyIncome;
        employments[index].incomes[0].businessType = employment.businessType;
        employments[index].incomes[0].typeOfIncome = typeOfIncome;

        var incomeItem = this.incomes.find(
          (i) => i.guid === employment.guid
        );
        if (incomeItem) {
          const index = this.incomes.indexOf(incomeItem);
          const updatedIncomeItem = this.toIncomeItem(
            employment,
            employment.incomes[0]
          );
          this.incomes[index] = updatedIncomeItem;
        }
        this.employmentChanged.emit();
      }
    }
  };

  private showNonEmploymentIncomeDialog = (
    header: string,
    label: string,
    typeOfIncome: TypeOfIncome,
    income?: Income
  ) => {
    const modal = this._modalService.show(
      SimpleIncomeDialogComponent,
      this.modalOptions
    );
    modal.componentInstance.header = header;
    modal.componentInstance.label = label;
    if (income) {
      modal.componentInstance.income = income;
    } else {
      modal.componentInstance.typeOfIncome = typeOfIncome;
    }
    modal.result.then(
      (income) => {
        this.onSaveClickedOnIncomeModalForEdit(income, typeOfIncome);
      },
      (error) => { }
    );
  };

  private getHeaderForNonEmploymentIncome = (typeOfIncome: TypeOfIncome): string => {
    const type = this._incomeTypes.find(it => it.value === typeOfIncome);
    if (type) {
      return type.name;
    }
    return 'Other';
  }
}
