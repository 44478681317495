export class Utils {

  static containsDigit = (value: string): boolean => {
    const regexp = /[0-9]/g;
    return regexp.test(value);
  };

  static containsUppercase = (value: string): boolean => {
    const regexp = /[A-Z]/g;
    return regexp.test(value);
  };

  static containsLowercase = (value: string): boolean => {
    const regexp = /[a-z]/g;
    return regexp.test(value);
  };

  static containsSpecialCharacter = (value: string): boolean => {
    const regexp = /[^a-zA-Z0-9]/g;
    return regexp.test(value);
  }

  static removeCountryCodeIfAny = (phoneNumber: string): string => {
    if (phoneNumber && phoneNumber.length > 10 && phoneNumber.startsWith("1")) {
      return phoneNumber.substring(phoneNumber.length - 10);
    }
    return phoneNumber;
  }

  static getUniqueId = () => {
    const uniqueId = Date.now() & 0xffffffff;
    return uniqueId < 0 ? uniqueId : uniqueId * -1;
  }
}
