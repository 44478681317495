import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { AddressAutocompleteComponent } from 'projects/shared/address-autocomplete/address-autocomplete.component';
import { Address } from '../../models/address-model';
import { CountryEnumerationItem, EnumsService } from '../../services/enums.service';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { v4 as uuidv4 } from 'uuid';
import { ApplicationContextService } from '../../services/application-context.service';
import { ConfigurableFieldHostComponent } from '../configurable-field-host.component';
import { ZipCodeService } from '../../services/zip-code.service';
import { ZipCodeLookupResult } from '../../models/zipcode-lookup-result.model';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { MakeProvider } from '../abstract-value-accessor';
import { formViewProvider } from '../../services/form-view.provider';
import * as _ from 'lodash';

@Component({
  selector: 'address',
  templateUrl: 'address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [MakeProvider(AddressComponent)],
  viewProviders: [formViewProvider]
})
export class AddressComponent extends ConfigurableFieldHostComponent implements OnInit {

  @ViewChild('addressForm') addressForm: NgForm | undefined;

  @ViewChild(AddressAutocompleteComponent) addressAutoComplete:
    | AddressAutocompleteComponent
    | undefined;

  @ViewChild('stateOfAddress') stateElement: ElementRef | undefined;
  @ViewChild('zipCodeOfAddress') zipCodeElement: ElementRef | undefined;
  @ViewChild('cityOfAddress') cityElement: ElementRef | undefined;
  @ViewChild('control') zipCode: NgModel;
  @ViewChild('control') input: ElementRef<HTMLInputElement>;

  @Input()
  address: Address;

  @Input()
  canSetTbd: boolean = false;

  @Input()
  required: boolean = true;

  @Input()
  inEditMode: boolean = false;

  @Input()
  addressTbdLabel: string = 'TBD';

  @Input()
  showCountry: boolean = false;

  @Input()
  showCounty: boolean = false;

  @Input()
  requireUserToEnterZipCodeWhenTbd: boolean = false;

  @Input()
  fieldConfigSettings: AddressFieldConfigSettings = new AddressFieldConfigSettings();

  @Input()
  zipCodeLookupEnabled: boolean = false;

  @Input()
  set isTbd(value: boolean) {
    this._isTbd = value;
    this.onIsTbdChanged();
  }

  get isTbd(): boolean {
    return this._isTbd;
  }

  @Input()
  isTbdDisabled: boolean = false;

  @Input()
  set availableStates(availableStates: EnumerationItem[]) {
    if (availableStates && availableStates.length) {
      this.states = availableStates;
    } else {
      this.states = this._enumsService.states;
    }
  }

  @Output()
  addressTbdLabelChanged: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  addressChanged: EventEmitter<Address> = new EventEmitter<Address>();

  @Output()
  addressIsTbdChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  countryChanged: EventEmitter<string> = new EventEmitter<string>();

  states: EnumerationItem[] = [];
  countries: CountryEnumerationItem[] = [];

  uniqueId: string;
  suggestions: ZipCodeLookupResult[] = [];
  loadingZipcodes: boolean = false;
  placeHolder: string;
  mask: string;
  pattern: string;

  public isZipKnownByTheBorrower: boolean = false;

  private _isTbd: boolean = false;

  constructor(
    private readonly _enumsService: EnumsService,
    private readonly _ctxService: ApplicationContextService,
    private readonly _zipCodeService: ZipCodeService,
  ) {
    super();
    if (!this.address) {
      this.address = new Address();
    }
    this.uniqueId = uuidv4();
  }

  ngOnInit() {
    if (!this.states || !this.states.length) {
      this.states = this._enumsService.states;
    }
    if (
      this.canSetTbd &&
      this.address.address1?.toLocaleLowerCase() === 'tbd'
    ) {
      this._isTbd = true;
    }
    if (!this.address.state) {
      this.address.state = null;
    }

    if (this.showCountry) {
      this._ctxService.context.subscribe(ctx => {
        this.countries = ctx.globalConfig.countries.map(c => {
          c.alpha2 = c.alpha2.toLowerCase();
          return c;
        });
      });
    }

    if (this.address && !this.address.country) {
      this.address.country = "us";
    }

    if (this.requireUserToEnterZipCodeWhenTbd) {
      this.isZipKnownByTheBorrower = true;
    }

    this.placeHolder = 'XXXXX';
    this.mask = '00000';
    this.pattern = '^[0-9]{5}?$'
  }

  onIsTbdChanged = () => {
    if (this.isTbd) {
      this.address.address1 = 'TBD';
      if (!this.isZipKnownByTheBorrower) {
        this.nullifyAddressWhenTbd();
      }
    } else {
      this.address.address1 = '';
    }
    this.addressIsTbdChanged.emit(this.isTbd);
  };

  selectZipcode = (suggestion: ZipCodeLookupResult) => {
    this.sendSelectionChanges(suggestion);
  }

  onAddressTbdLabelChanged = () => {
    this.addressTbdLabelChanged.emit(this.addressTbdLabel);
  }

  onAnswerForZipcodeQuestionChanged = () => {
    if (!this.isZipKnownByTheBorrower) {
      this.nullifyAddressWhenTbd();
    }
  };

  setManuallyEnteredAddress(address: string) {
    this.address.address1 = address;
  }

  setGooglePickedAddress(address: Address) {
    this.address.address1 = address.address1;
    this.address.city = address.city;
    this.address.state = address.state;
    this.address.zipCode = address.zipCode;
    this.address.county = address.county;
  }

  onAddressChanged = () => {
    this.addressChanged.emit({ ...this.address });
  }

  searchZipcode = (selectionsDropdown: NgbDropdown) => {
    this.loadingZipcodes = true;
    this.suggestions = [];

    if (!this.address.zipCode) {
      this.loadingZipcodes = false;
      return;
    }

    this._zipCodeService.lookupZipCode(this.address.zipCode, true)
      .subscribe((suggestions) => {
        this.suggestions = suggestions;
        this.loadingZipcodes = false;
        selectionsDropdown.open();
      });
  }

  onCountryChanged = () => {
    if (this.address && this.address.country && this.address.country != "us") {
      this.address.address1 = "";
      this.address.city = "";
      this.address.state = "";
      this.address.zipCode = "";
      this.address.county = "";
    }

    this.countryChanged.emit(this.address.country);
  }

  validate = () => {
    this.addressForm?.form.markAllAsTouched();
    this.addressAutoComplete?.markAsTouched();
    if (this.addressForm?.form.valid) {
      return true;
    }
    return false;
  };

  private nullifyAddressWhenTbd = () => {
    this.address.zipCode = null;
    this.address.city = null;
    this.address.state = null;
    this.address.county = null;
  };

  private sendSelectionChanges = (suggestion: ZipCodeLookupResult) => {
    if (suggestion) {
      this.address.city = _.startCase(_.toLower(suggestion.city));
      this.address.state = suggestion.state.toLowerCase();
      this.address.zipCode = suggestion.zipcode;
      this.address.county = _.startCase(_.toLower(suggestion.county));
      this.addressChanged.emit({ ...this.address });
    } else {

    }
  }
}

export class AddressFieldConfigSettings {
  stateField: string = 'state';
}

