import { Component, Injector, OnInit } from '@angular/core';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { finalize } from 'rxjs/operators';
import { SubmitApplicationStep } from '../../../models/wizard/submit-application-step.model';
import { WizardStepComponentBase } from '../wizard-step-base.component';

declare const Swal: any;

@Component({
  selector: 'submit-application-step',
  templateUrl: 'submit-application-step.component.html'
})

export class SubmitApplicationStepComponent extends WizardStepComponentBase<SubmitApplicationStep> implements OnInit {

  public isSubmitted = false;
  public isSuccess = false;

  constructor(private readonly _injector: Injector,
    private readonly _environmentService: EnvironmentService) {
    super(_injector);
  }

  errorMessage: string = "";
  errorTitle: string = "";

  ngOnInit() {
    this.nextButtonText = "Submit Your Information";
  }

  onNextClicked() {
    if (this.wizardFlowService.isEditEnabled) {
      this.isSubmitted = true,
      this.isSuccess = false;
      this.errorMessage = "You're an admin editing the flow. You will not be able to submit an information.";
      this.errorTitle = "Will not submit the information!"
      return;
    }
    this.startSpinner();
    this.wizardFlowService.context.borrowerAppStatus.details.applicationSubmitted = true;

    this.mortgageApplicationService.saveAppStatus(this.wizardFlowService.context.mortgage.applicationId,
      this.wizardFlowService.context.mortgage.mortgageId, this.wizardFlowService.context!.currentBorrower!.borrowerId!,
      this.wizardFlowService.context.borrowerAppStatus).subscribe(result => {
        this.mortgageApplicationService.submitApplication(this.mortgageApplication.applicationId).pipe(
          finalize(() => {
            this.stopSpinner();
          })
        ).subscribe(
            response => {
              this.isSubmitted = true;
              this.isSuccess = true;
              Swal.fire(
                'Success!',
                'Your information has been successfully submitted.',
                'success'
              );
              setTimeout(() => {
                //redirect
                const authorizationDataKey: string = "authorizationData";
                const authDataObj = JSON.parse(localStorage.getItem(authorizationDataKey)!);
                const token = authDataObj.token;
                let redirectUrl = this._environmentService.borrowerPortalURI + "loans-redirect/"
                  + this.mortgageApplication.applicationId + "?authToken=" + token;
                window.location.href = redirectUrl;
              }, 2000);
            },
            error => {
              this.isSubmitted = true;
              this.isSuccess = false;
              this.errorTitle = "Error!";
              this.errorMessage = "Something went wrong, please try again";
            }
          )
      }, error => {
        this.isSubmitted = true;
        this.isSuccess = false;
        this.errorTitle = "Error!";
        this.errorMessage = "Something went wrong, please try again";
      })
  }
}
