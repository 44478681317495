

  <div class="modal-header">

    <h2>{{header}}</h2>

    <!-- hack to fool ngbModal autofocus -->
    <input type="text" style="display:none" />

    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" aria-label="Close"
      (click)="activeModal.dismiss('Cross click')">
      <span class="svg-icon svg-icon-2x">
        <close-x-svg-icon></close-x-svg-icon>
      </span>
    </div>
  </div>

  <div class="modal-body py-10 px-lg-17">
    <form #employmentInfoForm="ngForm" class="form" novalidate id="employmentInfoForm" name="employmentInfoForm">
      <div class="me-n7 pe-7">
        <div class="row mb-5" *ngIf="!inEditMode">
          <div class="col-md-6 fv-row">
            <label class="required form-label mb-2">Borrower</label>
            <borrower-picker #borrowerPicker [required]="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="employment.borrowerId" [borrowers]="borrowers"></borrower-picker>
          </div>
          <div class="col-md-6 fv-row" *ngIf="isMilitaryRelated">
            <label class="{{!isRetired ? 'required': ''}} form-label mb-2">Type of Pay</label>
            <select name="militaryPayType"
              [required]="!isRetired"
              [ngClass]="{'is-invalid' : selectedMilitaryPayType && selectedMilitaryPayType.touched && selectedMilitaryPayType.invalid}"
              #selectedMilitaryPayType="ngModel"
              class="form-select form-select-solid"
              data-control="select2"
              [(ngModel)]="militaryPayType"
              data-control="select2" data-hide-search="true" data-placeholder="Select a Business Type">
              <option [ngValue]="null"></option>
              <option [ngValue]="'Employment'">Employment</option>
              <option [ngValue]="'Disability'">Disability</option>
            </select>
            <div class="invalid-feedback">Military Pay Type is required.</div>
          </div>
        </div>

        <ng-container *ngIf="militaryPayType === 'Employment'">
          <div class="row mb-5" *ngIf="isBusinessSelfEmployment && !isHidden('employment.businessType')">
            <div class="col-md-12 fv-row">
              <label class="form-label mb-2" [ngClass]="{'required' : isRequired('employment.businessType') && !isRetired}">
                {{getLabel('employment.businessType', 'Business Type')}}</label>
              <select name="businessType"
                [required]="isRequired('employment.businessType') && !isRetired"
                [ngClass]="{'is-invalid' : businessType && businessType.touched && businessType.invalid}"
                #businessType="ngModel" class="form-select form-select-solid" data-control="select2" [(ngModel)]="employment.businessType"
                data-control="select2" data-hide-search="true" data-placeholder="Select a Business Type">
                <option></option>
                <option *ngFor="let businessType of businessTypes; let i = index" value="{{businessType.value}}">
                  {{businessType.name}}
                </option>
              </select>
              <div class="invalid-feedback">Business Type is required.</div>
            </div>
          </div>
          <div class="row mb-5" *ngIf="isBusinessSelfEmployment">
            <div class="col-md-6 fv-row" *ngIf="!isHidden('employment.borrowerOwnershipShare')">
              <label class="form-label mb-2" [ngClass]="{'required' : isRequired('employment.borrowerOwnershipShare') && !isRetired}">{{getLabel('employment.borrowerOwnershipShare', 'Ownership Share')}}</label>
              <select name="borrowerOwnershipShare" [ngClass]="{'is-invalid' : borrowerOwnershipShare && borrowerOwnershipShare.touched && borrowerOwnershipShare.invalid}"
                [required]="isRequired('employment.borrowerOwnershipShare') && !isRetired"
                #borrowerOwnershipShare="ngModel" class="form-select form-select-solid" [(ngModel)]="employment!.borrowerOwnershipShare"
                data-control="select2" data-hide-search="true" data-placeholder="Choose one">
                <option></option>
                <option *ngFor="let ownershipShareType of ownershipShareTypes; let i = index" value="{{ownershipShareType.value}}">
                  {{ownershipShareType.name}}
                </option>
              </select>
              <div class="invalid-feedback">Ownership Share is required.</div>
            </div>
            <div class="col-md-6 fv-row" *ngIf="!isHidden('employment.selfEmploymentMonthlyIncomeOrLoss')">
              <label class="form-label mb-2" [ngClass]="{'required' : isRequired('employment.selfEmploymentMonthlyIncomeOrLoss') && !isRetired}">{{getLabel('employment.selfEmploymentMonthlyIncomeOrLoss', 'Monthly Pay')}}</label>
              <input class="form-control form-control-solid"
                [allowNegativeNumbers]="true"
                mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
                [ngClass]="{'is-invalid' : selfEmploymentMonthlyIncomeOrLoss && selfEmploymentMonthlyIncomeOrLoss.touched && selfEmploymentMonthlyIncomeOrLoss.invalid}"
                #selfEmploymentMonthlyIncomeOrLoss="ngModel"
                [required]="isRequired('employment.selfEmploymentMonthlyIncomeOrLoss') && !isRetired" [(ngModel)]="employment!.selfEmploymentMonthlyIncomeOrLoss" placeholder=""
                name="selfEmploymentMonthlyIncomeOrLoss" />
              <div class="invalid-feedback">Monthly Income/Loss is required.</div>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-6 fv-row" *ngIf="!isHidden('employment.employer')">
              <label class="form-label mb-2" [ngClass]="{'required' : isRequired('employment.employer') && !isRetired}">{{getLabel('employment.employer', 'Employer Name')}}</label>
              <input type="text" class="form-control form-control-solid" *ngIf="employment.address && employment.address.country && employment.address.country != 'us'; else usaEmployer"
              [required]="isRequired('employment.employer') && !isRetired"
              [disabled]="isRetired"
              [ngClass]="{'is-invalid' : employer && employer.touched && employer.invalid}" #employer="ngModel"
              [(ngModel)]="employment.employer" placeholder="" name="employer" id="employer" />

              <ng-template #usaEmployer>
                <input type="text" class="form-control form-control-solid"
                  [required]="isRequired('employment.employer') && !isRetired"
                  [disabled]="isRetired"
                  ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"
                  [ngClass]="{'is-invalid' : employer && employer.touched && employer.invalid}" #employer="ngModel"
                  [(ngModel)]="employment.employer" placeholder="" name="employer" id="employer" />
              </ng-template>
              <div class="invalid-feedback">Employer name is required.</div>
            </div>
            <div class="col-md-6 fv-row" *ngIf="!isHidden('employment.isPrimary')">
              <label class="form-label mb-2" [ngClass]="{'required' : isRequired('employment.isPrimary') && !isRetired}">{{getLabel('employment.isPrimary', 'Job Type')}}</label>
              <select name="jobType" [ngClass]="{'is-invalid' : jobType && jobType.touched && jobType.invalid}"
                [required]="isRequired('employment.isPrimary') && !isRetired"
                #jobType="ngModel" class="form-select form-select-solid" [(ngModel)]="employment!.isPrimary"
                data-control="select2" data-hide-search="true" data-placeholder="Choose one">
                <option *ngFor="let jobType of jobTypes; let i = index" value="{{jobType.value}}">
                  {{jobType.name}}
                </option>
              </select>
              <div class="invalid-feedback">Job Type is required.</div>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-md-6 fv-row" *ngIf="!isHidden('employment.position')">
              <label class="form-label mb-2" [ngClass]="{'required' : isRequired('employment.position') && !isRetired}">{{getLabel('employment.position', 'Position')}}</label>
              <input type="text" class="form-control form-control-solid"
                [ngClass]="{'is-invalid' : position && position.touched && position.invalid}" #position="ngModel"
                [(ngModel)]="employment!.position" placeholder="" name="position"
                [required]="isRequired('employment.position') && !isRetired" />
              <div class="invalid-feedback">Position is required.</div>
            </div>
            <div class="col-md-6 fv-row" *ngIf="!isHidden('employment.employmentType')">
              <label class="form-label mb-2" [ngClass]="{'required' : isRequired('employment.employmentType')}">{{getLabel('employment.employmentType', 'Current or Former')}}</label>
              <select name="employmentType" [required]="isRequired('employment.employmentType')"
                [ngClass]="{'is-invalid' : currentOrFormer && currentOrFormer.touched && currentOrFormer.invalid}"
                #currentOrFormer="ngModel" class="form-select form-select-solid" [(ngModel)]="employment!.employmentType"
                data-control="select2" data-hide-search="true" data-placeholder="Choose one">
                <option></option>
                <option value="CurrentEmployer">Current Employer</option>
                <option value="FormerEmployer">Former Employer</option>
              </select>
              <div class="invalid-feedback">Please select a value.</div>
            </div>
          </div>

          <div class="row" *ngIf="!isIndependentContractor">
            <address
              [address]="employment.address"
              [showCountry]="showCountry"
              [required]="!isRetired"
              (countryChanged)="onCountryChanged($event)">
            </address>
          </div>

          <div class="row g-9 mb-5">
            <div class="col-md-6 fv-row" *ngIf="!isHidden('employment.employerPhone')">
              <label class="form-label mb-2" [ngClass]="{'required' : isRequired('employment.employerPhone') && !isRetired}">{{getLabel('employment.employerPhone', 'Corporate Phone')}}</label>
              <input class="form-control form-control-solid" [(ngModel)]="employment!.employerPhone"
                [required]="isRequired('employment.employerPhone') && !isRetired"
                [ngClass]="{'is-invalid' : phone && phone.touched && phone.invalid}" #phone="ngModel"
                placeholder="" mask='(000) 000-0000' name="corporatePhone" />
            </div>
            <div class="col-md-6 fv-row" *ngIf="!isHidden('employment.yearsInLineOfWork')">
              <label class="form-label mb-2" [ngClass]="{'required' : isRequired('employment.yearsInLineOfWork') && !isRetired}">{{getLabel('employment.yearsInLineOfWork', 'Year(s) in Profession')}}</label>
              <input class="form-control form-control-solid" [patterns]="integerPattern" mask="XY" [validation]="false"
                [required]="isRequired('employment.yearsInLineOfWork') && !isRetired"
                [ngClass]="{'is-invalid' : years && years.touched && years.invalid}" #years="ngModel"
                [(ngModel)]="employment!.yearsInLineOfWork" placeholder="" name="years" />
              <div class="invalid-feedback">Years in profession is required.</div>
            </div>
          </div>
          <div>
            <div class="row g-9 mb-5" *ngIf="isCurrent || isPrevious">
              <div class="col-md-6 fv-row" *ngIf="!isHidden('employment.startDate')">
                <label class="form-label mb-2" [ngClass]="{'required' : isRequired('employment.startDate')}">{{getLabel('employment.startDate', 'Start Date')}}</label>
                <input class="form-control form-control-solid" matInput mask="M0/d0/0000" [showMaskTyped]="true" [specialCharacters]="[ '/' ]"
                  [dropSpecialCharacters]="false" inputmode="numeric"
                  [ngClass]="{'is-invalid' : startDate && startDate.touched && startDate.invalid}" #startDate="ngModel"
                  [required]="isRequired('employment.startDate')" inputmode="numeric"
                  [(ngModel)]="employment!.startDate" placeholder="" name="startDate" ngControl="startDate" id="startDate"
                  datePicker/>
                <div [ngStyle]="{'display':startDate && startDate.touched && startDate.invalid ? 'block' : 'none' }" class="invalid-feedback">Start date is required.</div>
              </div>

              <div class="col-md-6 fv-row" *ngIf="isPrevious && !isHidden('employment.endDate')">
                <label class="form-label mb-2" [ngClass]="{'required' : isRequired('employment.endDate') && !isRetired}">{{getLabel('employment.endDate', 'End Date')}}</label>

                <input class="form-control form-control-solid"  matInput mask="M0/d0/0000" [showMaskTyped]="true"
                  [specialCharacters]="[ '/' ]" [dropSpecialCharacters]="false" datePicker inputmode="numeric"
                  [ngClass]="{'is-invalid' : endDate && endDate.touched && endDate.invalid}" #endDate="ngModel" datePicker
                  [required]="isRequired('employment.endDate') && !isRetired" inputmode="numeric"
                  [(ngModel)]="employment!.endDate" placeholder="" name="endDate" ngControl="endDate" id="endDate"  />
                  <div [ngStyle]="{'display':endDate && endDate.touched && endDate.invalid ? 'block' : 'none' }" class="invalid-feedback">End date is required.</div>
                  <div [ngStyle]="{'display':endDate && endDate.touched && isEndDateMessageShowing ? 'block' : 'none' }" class="invalid-feedback">End date must be greater than start date</div>

              </div>
              <div class="col-md-6 fv-row" *ngIf="!isPrevious">
              </div>
            </div>
          </div>
          <div class="row g-9 mb-5">
            <div class="col-md-6 fv-row" *ngIf="!isBusinessSelfEmployment && !isHidden('monthlyPay')">
              <label class="form-label mb-2" [ngClass]="{'required' : isRequired('monthlyPay') && !isRetired}">{{getLabel('monthlyPay', 'Monthly Pay')}}</label>
              <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
                [ngClass]="{'is-invalid' : monthlyPay && monthlyPay.touched && monthlyPay.invalid}"
                #monthlyPay="ngModel"
                [required]="isRequired('monthlyPay') && !isRetired" [(ngModel)]="employment!.monthlyIncome" placeholder="" name="monthlyPay" />
              <div class="invalid-feedback">Monthly pay is required.</div>
            </div>
            <div class="col-md-3 fv-row">
              <div class="form-check form-switch form-check-custom form-check-solid" style="padding-top: 30px;">
                <input class="form-check-input h-20px w-30px" type="checkbox" name="isRetired" [(ngModel)]="isRetired" (ngModelChange)="isRetiredChanged()" id="isRetired" />
                <label class="form-check-label form-label" for="isRetired">
                  Is Retired?
                </label>
              </div>
            </div>
            <div class="col-md-3 fv-row" *ngIf="isIndependentContractor && !isHidden('employment.selfEmployed')">
              <div class="form-check form-switch form-check-custom form-check-solid" style="padding-top: 30px;">
                <input class="form-check-input h-20px w-30px" [(ngModel)]="employment!.selfEmployed"
                [required]="isRequired('employment.selfEmployed') && !isRetired"
                [ngModelOptions]="{standalone: true}"
                type="checkbox"  id="selfEmployed" />
                <label class="form-check-label form-label" [ngClass]="{'required' : isRequired('employment.selfEmployed') && !isRetired}" for="selfEmployed">
                  {{getLabel('employment.selfEmployed', 'Self Employed')}}
                </label>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="militaryPayType === 'Disability'">
          <div class="row g-6 mb-5">
            <div class="col-md-6 fv-row" *ngIf="!isBusinessSelfEmployment && !isHidden('monthlyPay')">
              <label class="form-label mb-2" [ngClass]="{'required' : isRequired('monthlyPay')}">{{getLabel('monthlyPay', 'Monthly Pay')}}</label>
              <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
                [ngClass]="{'is-invalid' : monthlyPay && monthlyPay.touched && monthlyPay.invalid}"
                #monthlyPay="ngModel"
                [required]="isRequired('monthlyPay')" [(ngModel)]="employment!.monthlyIncome" placeholder="" name="monthlyPay" />
              <div class="invalid-feedback">Monthly pay is required.</div>
            </div>
            <div class="col-md-6 fv-row">
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </div>

  <div class="modal-footer flex-center">
    <button type="button" class="btn btn-white me-3" (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="onSaveClicked()">Ok</button>
  </div>




