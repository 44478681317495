<form #borrowerInfoForm="ngForm" novalidate id="borrowerInfoForm" name="borrowerInfoForm">
  <div class="w-100">
    <div class="row" [ngClass]="{'mb-5': !isMobile}">
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.firstName')">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span [ngClass]="{'required': isRequired('borrower.firstName')}">{{getLabel('borrower.firstName', 'First Name')}}
          </span>
        </label>
        <div class="position-relative">
          <input type="text" class="form-control form-control-solid" [(ngModel)]="borrower.firstName"
            [required]="isRequired('borrower.firstName')" placeholder="" name="firstName"
            [ngClass]="{'is-invalid' : firstName && firstName.touched && firstName.invalid}" #firstName="ngModel" />
          <!-- <div class="invalid-feedback">Please enter a first name.</div> -->
        </div>
      </div>
      <div class="col-md-3 fv-row">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span>Middle Name</span>
        </label>
        <div class="position-relative">
          <input type="text" class="form-control form-control-solid" [(ngModel)]="borrower.middleName" placeholder=""
            name="middleName" />
        </div>
      </div>
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.lastName')">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span [ngClass]="{'required': isRequired('borrower.lastName')}">{{getLabel('borrower.lastName', 'Last Name')}}
          </span>
        </label>
        <div class="position-relative">
          <input type="text" class="form-control form-control-solid" [(ngModel)]="borrower.lastName"
            [required]="isRequired('borrower.lastName')" placeholder="" name="lastName"
            [ngClass]="{'is-invalid' : lastName && lastName.touched && lastName.invalid}" #lastName="ngModel" />
          <!-- <div class="invalid-feedback">Please enter a last name.</div> -->
        </div>
      </div>
      <div class="col-md-3 fv-row">
        <label class="form-label">Suffix</label>
        <select class="form-select form-select-solid" data-control="select2" name="suffix"
          [(ngModel)]="borrower.nameSuffix">
          <option [ngValue]="null">Select one...</option>
          <option value="Jr">Jr</option>
          <option value="Sr">Sr</option>
          <option value="I">I</option>
          <option value="II">II</option>
          <option value="III">III</option>
        </select>
      </div>
    </div>
    <div class="row" [ngClass]="{'mb-5': !isMobile}">
      <div class="col-md-6 fv-row">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span class="required">E-mail</span>
        </label>
        <div class="position-relative">
          <input type="text" email trim class="form-control form-control-solid" [(ngModel)]="borrower.primaryEmail" required
            placeholder="" name="email" [ngClass]="{'is-invalid' : email && email.touched && email.invalid}"
            #email="ngModel" />
          <!-- <div class="invalid-feedback">Please enter an e-mail.</div> -->
        </div>
      </div>
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.mobilePhone')">
        <label class="d-flex align-items-center fs-6 fw-bold form-label mb-2">
          <span [ngClass]="{'required': isRequired('borrower.mobilePhone')}">{{getLabel('borrower.mobilePhone', 'Phone Number')}}
          </span>
        </label>
        <div class="position-relative">
          <input type="text" class="form-control form-control-solid" [(ngModel)]="borrower.mobilePhone"
            [required]="isRequired('borrower.mobilePhone')" placeholder="" mask='(000) 000-0000' name="phone"
            #phone="ngModel" [ngClass]="{'is-invalid' : phone && phone.touched && phone.invalid}" />
        </div>
      </div>

      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.maritalStatus')">
        <label class="form-label"
          [ngClass]="{'required': isRequired('borrower.maritalStatus')}">{{getLabel('borrower.maritalStatus', 'Marital Status')}}
        </label>
        <select class="form-select form-select-solid" data-control="select2" name="maritalStatus"
          [(ngModel)]="borrower.maritalStatus"
          [ngClass]="{'is-invalid' : maritalStatus && maritalStatus.touched && maritalStatus.invalid}"
          #maritalStatus="ngModel" [required]="isRequired('borrower.maritalStatus')">
          <option [ngValue]="null">Select one...</option>
          <option value="Single">Single</option>
          <option value="Married">Married</option>
          <option value="Separated">Separated</option>
          <option value="Other">Other</option>
        </select>
      </div>
    </div>
    <div class="row" [ngClass]="{'mb-5': !isMobile}">
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.dateOfBirth')">
        <label class="form-label" [ngClass]="{'required': isRequired('borrower.dateOfBirth')}">{{getLabel('borrower.dateOfBirth', 'Birth Date')}}</label>
        <div class="position-relative d-flex align-items-center">
          <input class="form-control form-control-solid flatpickr-disabled" matInput mask="M0/d0/0000" [showMaskTyped]="true"
            [specialCharacters]="[ '/' ]" [dropSpecialCharacters]="false" datePicker #birthDate="ngModel" inputmode="numeric"
            [(ngModel)]="borrower.dateOfBirth" placeholder="" name="birthDate{{borrower.borrowerId}}" ngControl="birthDate" id="birthDate{{borrower.borrowerId}}"
            [ngClass]="{'is-invalid' : birthDate && birthDate.touched && birthDate.invalid}"
            [required]="isRequired('borrower.dateOfBirth')"/>
        </div>
      </div>
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.socialSecNum')">
        <label class="form-label" [ngClass]="{'required': isRequired('borrower.socialSecNum')}">{{getLabel('borrower.socialSecNum', 'Social Security Number')}}</label>
        <div class="position-relative d-flex align-items-center">
          <input class="form-control form-control-solid" matInput mask="000-00-0000" [showMaskTyped]="true"
            [(ngModel)]="borrower.socialSecNum" #socialSecNum="ngModel" [specialCharacters]="[ '-' ]"
            [dropSpecialCharacters]="false" [patterns]="ssnPattern" placeholder="Social Security Number" name="ssn" id="ssn"
            [required]="isRequired('borrower.socialSecNum')" [ngClass]="{'is-invalid' : socialSecNum && socialSecNum.touched && socialSecNum.invalid}" />
        </div>
      </div>
      <div class="col-md-3 fv-row" *ngIf="!isHidden('borrower.declarations.residenceStatus')">
        <label class="form-label" [ngClass]="{'required': isRequired('borrower.declarations.residenceStatus')}">{{getLabel('borrower.declarations.residenceStatus', 'Citizenship')}}</label>
        <select class="form-select form-select-solid" data-control="select2" name="citizenship"
          [(ngModel)]="borrower.declarations!.residenceStatus" #residenceStatus="ngModel"
          [ngClass]="{'is-invalid' : residenceStatus && residenceStatus.touched && residenceStatus.invalid}"
          [required]="isRequired('borrower.declarations.residenceStatus')">
          <option [ngValue]="null" disabled>Select one...</option>
          <option value="USCitizen">US Citizen</option>
          <option value="PermanentResident">Permanent Resident</option>
          <option value="NonPermanentResidentAlien">Non-Permanent Resident Alien</option>
        </select>
      </div>
    </div>
    <div class="row mb-5 rounded p-4"
      [ngClass]="{
        'mt-10': !isMobile,
        'is-invalid' : mainBorrowerWillApplyForMeQuestion && mainBorrowerWillApplyForMeQuestion.touched && mainBorrowerWillApplyForMeQuestion.invalid,
        'border' : mainBorrowerWillApplyForMeQuestion && mainBorrowerWillApplyForMeQuestion.touched && mainBorrowerWillApplyForMeQuestion.invalid,
        'border-danger' : mainBorrowerWillApplyForMeQuestion && mainBorrowerWillApplyForMeQuestion.touched && mainBorrowerWillApplyForMeQuestion.invalid
      }">
      <div class="col-sm-6 fs-6 text-dark" style="padding-top: 4px;">Will you be filling out the application for this person?
      </div>
      <div class="col-md-2" [ngClass]="{'mt-2': isMobile}">
        <div class="d-flex">
          <div class="form-check form-check-custom form-check-solid">
            <input
              class="form-check-input"
              name="willYouBeApplyingForCoBorrowerQuestion"
              required
              [value]="true"
              type="radio"
              id="aYes"
              [(ngModel)]="mainBorrowerWillApplyForMe" #mainBorrowerWillApplyForMeQuestion="ngModel"
              (ngModelChange)="onMainBorrowerWillApplyForMeChanged($event)"/>
            <label style="padding-right: 6px;" class="form-check-label" for="aYes">Yes</label>
          </div>
          <div class="form-check form-check-custom form-check-solid ms-8">
            <input
              class="form-check-input"
              name="willYouBeApplyingForCoBorrowerQuestion"
              required
              [value]="false"
              type="radio"
              id="aNo"
              [(ngModel)]="mainBorrowerWillApplyForMe" #mainBorrowerWillApplyForMeQuestion="ngModel"
              (ngModelChange)="onMainBorrowerWillApplyForMeChanged($event)"/>
            <label style="padding-right: 6px;" class="form-check-label" for="aNo">No</label>
          </div>
        </div>
      </div>
    </div>
    <div class="invalid-feedback">Please specify if you will complete the application for this co-borrower or not.</div>
  </div>
</form>
