import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from "@angular/forms";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { Subscription } from 'rxjs';
import { Borrower } from '../../../models/borrower-model';
import { Employment } from '../../../models/employment-model';
import { TypeOfIncome } from '../../../models/enums/type-of-income.enum';
import { EnumsService } from '../../../services/enums.service';
import { UtilsService } from '../../../services/utils.service';
import { AddressComponent } from '../../address/address.component';
import { BorrowerPicker } from '../../borrower-picker/borrower-picker.component';
import { ConfigurableFieldHostComponent } from '../../configurable-field-host.component';

declare const $: any;

@Component({
  selector: 'employment-dialog',
  templateUrl: 'employment-dialog.component.html',
  styleUrls: ['./employment-dialog.component.scss'],
})
export class EmploymentDialogComponent
  extends ConfigurableFieldHostComponent
  implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('employmentInfoForm')
  employmentInfoForm: NgForm | undefined;

  @ViewChild(AddressComponent)
  addressComponent: AddressComponent | undefined;

  @ViewChild('borrowerPicker')
  borrowerPicker: BorrowerPicker | undefined;

  inEditMode: boolean = false;

  @Input()
  set employment(employment: Employment) {
    this._employment = employment;
    this.inEditMode = true;
  }

  get employment(): Employment {
    return this._employment;
  }

  @Input() borrowers: Borrower[] = [];

  @Input()
  typeOfIncome: TypeOfIncome;

  @Input()
  showCountry: boolean = false;

  selectedBorrowerId: number | undefined;

  integerPattern = {
    X: { pattern: new RegExp('[1-9]') },
    Y: { pattern: new RegExp('[0-9]') },
  };

  businessTypes: EnumerationItem[] = [];

  ownershipShareTypes: EnumerationItem[] = [];

  militaryPayType: string | null = null;

  options: any = {
    types: ['establishment'],
    componentRestrictions: { country: 'us' },
  };

  isEndDateMessageShowing: boolean = false;

  isRetired: boolean = false;

  private _jobTypes: any[] = [];

  private _subscriptionToBorrowerChanged: Subscription;

  private _employment: Employment;

  private _allJobTypes = [
    { name: 'Primary Employment', value: 'true' },
    { name: 'Secondary Employment', value: 'false' },
  ];

  private _onlySecondaryJobType = [
    { name: 'Secondary Employment', value: 'false' },
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private readonly _utilsService: UtilsService,
    private readonly _enumsService: EnumsService
  ) {
    super();
    this._employment = new Employment();
    this._employment.employmentType = 'CurrentEmployer';
    // this._employment.employmentId =
    //   this._utilsService.generateUniqueNumericId();
    this.typeOfIncome = TypeOfIncome.Base;
    this._jobTypes = this._allJobTypes;
    this.businessTypes = this._enumsService.businessTypes;
    this.ownershipShareTypes = this._enumsService.ownershipShareTypes;
  }

  ngOnInit() {
    if ((!this.inEditMode && !this.isMilitaryRelated) || this.inEditMode) {
      this.militaryPayType = 'Employment';
    }
    if (this._employment.employmentId <= 0) {
      if (this.borrowers.length > 0) {
        this._employment.borrowerId = this.borrowers[0].borrowerId;
      }
    }
    $('.modal-body').scroll(function () {
      //Set new top to autocomplete dropdown
      let newTop = $('#employer').offset().top + $('#employer').outerHeight();
      $('.pac-container').css('top', newTop + 'px');
    });

    this.isRetired = this.employment.employer && this.employment.employer == "Retired" ? true : false;
  }

  ngAfterViewInit(): void {
    if (this.borrowerPicker) {
      this._subscriptionToBorrowerChanged =
        this.borrowerPicker.borrowerChangedEmitter.subscribe((e: any) =>
          this.onBorrowerChanged(e)
        );
    }
  }

  ngOnDestroy(): void {
    if (this._subscriptionToBorrowerChanged) {
      this._subscriptionToBorrowerChanged.unsubscribe();
    }
  }

  isRetiredChanged = () => {
    if (this.isRetired) {
      this.employment.employer = "Retired";
    }
    else {
      if (this.employment.employer && this.employment.employer == "Retired") {
        this.employment.employer = null;
      }
    }
  }

  public get header(): string {
    if (this.inEditMode) {
      return 'Editing - ' + this._employment.employer;
    }
    if (this.typeOfIncome === TypeOfIncome.MilitaryBasePay) {
      return 'Add Military Pay';
    } else if (this.typeOfIncome === TypeOfIncome.ContractBasis) {
      return 'Add Independent Contractor';
    } else if (this.typeOfIncome === TypeOfIncome.SelfEmploymentIncome) {
      return 'Add Business / Self Employment';
    }
    return 'Add Employment';
  }

  public get jobTypes() {
    return this._jobTypes;
  }

  handleAddressChange = (e: any) => {
    setTimeout(() => {
      this.employment.employer = e.name;
      e.address_components.forEach(
        (component: { types: any; long_name: any; short_name: any }) => {
          let types = component.types;
          types.forEach((type: string) => {
            if (type === 'locality' || type === 'sublocality') {
              this.employment.address.city = component.long_name;
            }
            if (type === 'administrative_area_level_1') {
              const state = this._enumsService.states.find(
                (s) => s.name === component.short_name
              );
              if (state) this.employment.address.state = state.value;
            }
            if (type === 'postal_code') {
              this.employment.address.zipCode = component.long_name;
            }
            if (type === 'street_number') {
              this.employment.address.address1 = component.short_name;
            }
            if (type === 'route') {
              this.employment.address.address1 += ' ' + component.short_name;
            }
          });
        }
      );
    }, 200);
  };

  onCountryChanged = (country: string) => {
    if(country && country != "us"){
      this.employment.employer = "";
    }
  }

  onSaveClicked = () => {
    this.borrowerPicker?.markAsTouched();
    this.employmentInfoForm?.form.markAllAsTouched();
    let valid: boolean | undefined = true;
    valid = this.employmentInfoForm?.form.valid && valid;
    if (this.addressComponent) {
      valid = this.addressComponent.validate() && valid;
    }

    // start/end date controls
    if (!this.isHidden('employment.startDate') && this.isPrevious && !this.isHidden('employment.endDate')) {
      if (new Date(this.employment.startDate) > new Date(this.employment.endDate)) {
        this.isEndDateMessageShowing = true;
        valid = false;
        return;
      }
    }

    if (!valid) {
      return;
    }

    let typeOfIncome = this.typeOfIncome;
    if (this.militaryPayType && this.militaryPayType === 'Disability') {
      typeOfIncome = TypeOfIncome.Disability;
    }
    const result: EmploymentAndIncomeType = {
      employment: this._employment,
      typeOfIncome: typeOfIncome,
    };
    this.activeModal.close(result);
  };

  public get isCurrent(): boolean {
    return this.employment?.employmentType == 'CurrentEmployer';
  }

  public get isPrevious(): boolean {
    return this.employment?.employmentType == 'FormerEmployer';
  }

  public get isIndependentContractor(): boolean {
    return this.typeOfIncome === TypeOfIncome.ContractBasis;
  }

  public get isMilitaryRelated(): boolean {
    return this.typeOfIncome === TypeOfIncome.MilitaryBasePay;
  }

  public get isBusinessSelfEmployment(): boolean {
    return this.typeOfIncome === TypeOfIncome.SelfEmploymentIncome;
  }

  private onBorrowerChanged = (borrowerId: any): void => {
    let borrower = this.borrowers.find((b) => b.borrowerId === borrowerId);
    if (borrower?.employments.find((e) => e.isPrimary)) {
      this._jobTypes = this._onlySecondaryJobType;
      this.employment.isPrimary = 'false';
    } else {
      this._jobTypes = this._allJobTypes;
      this.employment.isPrimary = 'true';
    }
  };
}

export class EmploymentAndIncomeType {
  employment: Employment;
  typeOfIncome: TypeOfIncome;
}
