import { Injectable } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import { ApplicationForUser } from '../models/borrower-portal/application-for-user-model';
import { ApplicationTasksModel } from '../models/borrower-portal/application-tasks-model';
import { BorrowerAndAgentMessageModel } from '../models/borrower-portal/borrower-and-agent-message-model';
import { ContactInfoModel } from '../models/borrower-portal/contact-info-model';
import { BorrowerDocumentModel } from '../models/borrower-portal/borrower-document-model';
import { BorrowerLoanMessageModel } from '../models/borrower-portal/borrower-loan-message-model';
import { LiveData } from '../models/borrower-portal/live-data-model';
import { PortalContent } from '../models/borrower-portal/portal-content-model-model';
import { PrequalDetailsModel } from '../models/borrower-portal/prequal-details.model';
import { ReferAFriendRequest } from '../models/borrower-portal/refer-a-friend-request.model';
import { ReferralAgent } from '../models/borrower-portal/referral-agent-model';
import { Referral } from '../models/borrower-portal/referral.model';
import { UserPrimaryBranchModel } from '../models/borrower-portal/user-primary-branch-model';
import { OpenSignerViewRequest } from '../models/open-signer-view-request.model';
import { OrderPaymentHistory } from '../models/order-payment-history.model';
import { PaymentInformation } from '../models/payment-information.model';
import { DataService } from './data.service';
import { PricingScenario } from '../models/pricing/pricing-scenario.model';
import { LoanFee } from '../models/fee/fee.model';
import { AgentRequestACallModel } from '../models/borrower-portal/agent-request-a-call.model';
import { GeneratePrequalLetterRequestModel } from '../models/borrower-portal/generate-prequal-letter-request.model';
import { GeneratePrequalLetterResponseModel } from '../models/borrower-portal/generate-prequal-letter-response.model';
import { ValidateESignPropertyInfoResponse } from '../models/e-sign/validate-esign-property-info.model';
import { CreateSignerViewResponse } from '../models/e-sign/create-signer-view-response.model';
import { ConfirmEsignTokenResponse } from '../models/e-sign/confirm-esign-token-response.mode';

@Injectable()
export abstract class BasePortalService {
  private _urlRoot: string;

  protected abstract get baseUrl(): string;

  constructor(protected readonly _dataService: DataService) { }

  abstract getContactInfoForApplication(
    appId: number
  ): Observable<ContactInfoModel>;

  getUserPrimaryBranch = (
    userId: string
  ): Observable<UserPrimaryBranchModel> => {
    return this._dataService.get(
      `api/${this.baseUrl}/GetUserPrimaryBranch?userId=${userId}`
    );
  };

  getPortalContent = (): Observable<PortalContent> => {
    return this._dataService.get(`api/${this.baseUrl}/GetPortalContent`);
  };

  getApplicationsForUser = (): Observable<ApplicationForUser[]> => {
    return this._dataService.get(`api/${this.baseUrl}/GetApplicationsForUser`);
  };

  getLoanDataForAppId = (
    appId: number | null
  ): Observable<ApplicationForUser> => {
    return this._dataService.get(
      `api/${this.baseUrl}/GetLoanDataForAppId/${appId}`
    );
  };

  getReferralAgents = (): Observable<ReferralAgent[]> => {
    return this._dataService.get(`api/${this.baseUrl}/ReferralAgents`);
  };

  getReferralsForUser = (): Observable<Referral[]> => {
    return this._dataService.get(`api/${this.baseUrl}/GetReferralsForUser`);
  };

  getLiveData = (): Observable<LiveData> => {
    return this._dataService.get(`api/${this.baseUrl}/LiveData`);
  };

  getTasksForAppId = (appId: number): Observable<ApplicationTasksModel> => {
    return this._dataService.get(
      `api/${this.baseUrl}/GetTasksForAppId/${appId}`
    );
  };

  getMessagesForApplication = (
    appId: number
  ): Observable<BorrowerLoanMessageModel[]> => {
    return this._dataService.get(`api/${this.baseUrl}/GetMessages/${appId}`);
  };

  getDocumentsForAppId = (
    appId: number
  ): Observable<BorrowerDocumentModel[]> => {
    return this._dataService.get(
      `api/${this.baseUrl}/GetDocumentsForAppId/${appId}`
    );
  };

  getPrequalDetailsForAppId = (
    appId: number
  ): Observable<PrequalDetailsModel> => {
    return this._dataService.get(
      `api/${this.baseUrl}/GetPrequalDetailForAppId/${appId}`
    );
  };

  uploadDocument = (
    taskId: number,
    borrowerNote: string,
    file: any
  ): Observable<any> => {
    return this._dataService.upload(
      `api/${this.baseUrl}/UploadDocument/${taskId}/${borrowerNote}`,
      file
    );
  };

  downloadFile = (fileGuid: string): Observable<any> => {
    return this._dataService.downloadFile(
      `api/${this.baseUrl}/DownloadFile/${fileGuid}`
    );
  };

  postMessage = (
    message: BorrowerAndAgentMessageModel
  ): Observable<boolean> => {
    return this._dataService.post(`api/${this.baseUrl}/PostMessage`, message);
  };

  postReferAFriend = (req: ReferAFriendRequest): Observable<boolean> => {
    return this._dataService.post(`api/${this.baseUrl}/ReferAFriend`, req);
  };

  markTaskAsNotApplicable = (
    loanDocTaskId: number,
    note: string
  ): Observable<any> => {
    const url = `api/${this.baseUrl}/task/${loanDocTaskId}/mark-na`;
    return this._dataService.post(url, note);
  };

  answerTaskQuestion = (
    loanDocTaskId: number,
    answer: string
  ): Observable<any> => {
    const url = `api/${this.baseUrl}/task/${loanDocTaskId}/answer-request-info`;
    return this._dataService.post(url, answer);
  };

  acceptESignConsent = (
    appId: number,
    borrowerId?: number,
    mortgageBorrowerId?: number
  ): Observable<any> => {
    let url = `api/${this.baseUrl}/Signing/acceptESignConsent?applicationId=${appId}`;
    url += borrowerId ? `&borrowerId=${borrowerId}` : '';
    url += mortgageBorrowerId
      ? `&mortgageBorrowerId=${mortgageBorrowerId}`
      : '';

    return this._dataService.post(url, {});
  };

  openSignerView = (
    taskId: number,
    req: OpenSignerViewRequest
  ): Observable<any> => {
    let url = `api/${this.baseUrl}/Signing/LoanDocTask/${taskId}/OpenSignerView`;
    return this._dataService.post(url, req);
  };

  getSigningOrder = (taskId: number): Observable<any> => {
    let url = `api/${this.baseUrl}/Signing/ordersForTasks?loanDocTaskIds=${taskId}`;
    return this._dataService.get(url);
  };

  onSignerViewSubmitted = (
    taskId: number,
    action: string
  ): Observable<{ signerViewUrl: string }> => {
    let url = `api/${this.baseUrl}/Signing/onSignerViewSubmitted?loanDocTaskId=${taskId}&action=${action}`;
    return this._dataService.post(url, {});
  };

  getLoginUser(): any {
    let authorizationData: any = localStorage.getItem('authorizationData');
    if (authorizationData) {
      authorizationData = JSON.parse(authorizationData);
      return authorizationData;
    }
  }

  orderPayment = (
    taskId: number,
    req: PaymentInformation
  ): Observable<OrderPaymentHistory> => {
    let url = `api/orderPayment?loanDocTaskId=${taskId}`;
    return this._dataService.post(url, req);
  };

  getScenariosForApp = (appId: number): Observable<PricingScenario[]> => {
    return this._dataService.get(
      `api/${this.baseUrl}/GetSharedScenariosforAppId/${appId}`
    );
  };

  getScenarioFees = (scenarioId: number): Observable<LoanFee[]> => {
    const url = `api/${this.baseUrl}/getScenarioFees/${scenarioId}`;
    return this._dataService.get(url);
  };

  revokeEConsent = (appId: number, borrowerId: number): Observable<boolean> => {
    return this._dataService.post(
      `api/${this.baseUrl}/revoke-econsent/${appId}/${borrowerId}`,
      {}
    );
  };

  postReqestACall = (req: AgentRequestACallModel): Observable<boolean> => {
    return this._dataService.post(`api/${this.baseUrl}/RequestACallback`, req);
  };

  generatePrequalLetterForApp = (
    appId: number,
    prequalDetails: GeneratePrequalLetterRequestModel
  ): Observable<GeneratePrequalLetterResponseModel> => {
    return this._dataService.post(
      `api/${this.baseUrl}/GeneratePrequalLetterForAppId/${appId}`,
      prequalDetails
    );
  };

  confirmEsignToken = (
    eSignToken: string
  ): Observable<ConfirmEsignTokenResponse> => {
    return this._dataService.postWithoutAuth(
      `api/${this.baseUrl}/signing/confirm-esign-token/${eSignToken}`,
      {}
    );
  };

  validateLoanForAnonymousEsign = (
    eSignToken: string,
    zipcode: string,
    last4DigitsOfSsn: string
  ): Observable<ValidateESignPropertyInfoResponse> => {
    return this._dataService.postWithoutAuth(
      `api/${this.baseUrl}/signing/validate-loan-info/${eSignToken}?subjectPropertyZip=${zipcode}&lastFour=${last4DigitsOfSsn}`,
      {}
    );
  };

  openEsignerViewForAnonymousEsign = (
    eSignToken: string,
    privateToken: string,
    loanDocTaskId: number,
    request: OpenSignerViewRequest
  ): Observable<CreateSignerViewResponse> => {
    return this._dataService.postWithoutAuth(
      `api/${this.baseUrl}/signing/LoanDocTask/${loanDocTaskId}/open-signer-view/${eSignToken}?pt=${privateToken}`,
      request
    );
  };

  acceptEsignConsentForAnonymousEsign = (
    eSignToken: string,
    privateToken: string,
    applicationId: number,
    borrowerId: number,
    mortgageBorrowerId: number
  ): Observable<any> => {
    return this._dataService.postWithoutAuth(
      `api/${this.baseUrl}/signing/accept-esign-consent/${eSignToken}?applicationId=${applicationId}&borrowerId=${borrowerId}&mortgageBorrowerId=${mortgageBorrowerId}&pt=${privateToken}`,
      {}
    );
  };

  onSignerViewSubmittedForAnonymousEsign = (
    eSignToken: string,
    loanDocTaskId: number,
    action: string
  ): Observable<{ signerViewUrl: string }> => {
    let url = `api/${this.baseUrl}/signing/on-signer-view-submitted/${eSignToken}?action=${action}&loanDocTaskId=${loanDocTaskId}`;
    return this._dataService.postWithoutAuth(url, {});
  };

  getShortLinkRedirectUrl = (shortCode: string, queryString: string): Observable<{url: string}> => {
    let url = `api/${this.baseUrl}/redirect/${shortCode}`;
    if (queryString) {
      url += `?queryString=${queryString}`;
    }
    return this._dataService.get(url);
  }
}
