import { Injectable } from "@angular/core";
import { UtilsService } from "projects/borrower-app/src/app/services/utils.service";
import { IDecideNextStep } from "projects/borrower-app/src/app/services/wizard/next-step-decider/next-step-decider.interface";
import { WizardFlowContext } from "../../../wizard-flow-context.model";
import { MyInfoStep } from "../../my-info-step.model";
import { FieldConfigUtils } from "../field-config-utils";
import { MyInfoStepConfig } from "../my-info-step-config.model";
import { NextStepDeciderFactory } from "./next-step-decider.factory";
import { StepFactoryBase } from "./step-factory-base";
import { ICreateStep } from "./step-factory.interface";

@Injectable()
export class MyInfoStepFactory extends StepFactoryBase<MyInfoStepConfig> implements ICreateStep {

  constructor(private readonly _nextStepDeciderFactory: NextStepDeciderFactory,
    private readonly _utilsService: UtilsService) {
      super();
  }

  configure(step: MyInfoStep, config: MyInfoStepConfig): void {
    config.explanation = step.explanation;
    config.title = step.title;
    config.heading = step.heading;
    config.groupId = step.groupId;
    config.configuresAddressHistory = step.configuresAddressHistory;
    config.configuresCoborrowers = step.configuresCoborrowers;
    config.borrowerFieldsConfig = step.borrowerFieldsConfig;
    config.addressFieldsConfig = step.addressFieldsConfig;
  }

  create(config: MyInfoStepConfig, wizardFlowContext: WizardFlowContext): MyInfoStep {
    let nextStepDecider: IDecideNextStep | undefined = undefined;
    if (config.nextStepDeciderConfig) {
        nextStepDecider = this._nextStepDeciderFactory.create(config.nextStepDeciderConfig, wizardFlowContext);
    }
    let myInfoStep = new MyInfoStep(config.id, config.name, config.path, nextStepDecider);
    myInfoStep.groupId = config.groupId;
    myInfoStep.title = config.title;
    myInfoStep.explanation = config.explanation;
    myInfoStep.heading = config.heading;
    myInfoStep.configuresAddressHistory = config.configuresAddressHistory;
    myInfoStep.configuresCoborrowers = config.configuresCoborrowers;
    let borrowerFieldsConfig = FieldConfigUtils.generateBorrowerFieldsConfig();
    if (config.borrowerFieldsConfig) {
      borrowerFieldsConfig = { ...borrowerFieldsConfig, ...config.borrowerFieldsConfig };
    }
    myInfoStep.borrowerFieldsConfig = borrowerFieldsConfig;
    myInfoStep.addressFieldsConfig = config.addressFieldsConfig ?? FieldConfigUtils.generateAddressFieldsConfig();
    return myInfoStep;
  }
}
