import { CategoryOfIncome } from "./enums/category-of-income.enum";
import { TypeOfIncome } from "./enums/type-of-income.enum";

export class Income {
  incomeId!: number;
  monthlyIncome: number | undefined;
  categoryOfIncome: CategoryOfIncome | undefined;
  typeOfIncomeOtherDescription: string = "";
  businessType: string | undefined;
  typeOfIncome: TypeOfIncome | undefined;
  borrowerId: number | undefined;
  companyId: string | undefined;

  // Not on the server side model
  guid: string = "";
}
