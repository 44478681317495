import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'employment',
  templateUrl: 'employment.component.html'
})

export class EmploymentComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
