import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MortgageApplication, MortgageCalculationDetails } from '../models/mortgage-app.model';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { Observable, of } from 'rxjs';
import { Asset } from '../models/asset.model';
import { DataService } from './data.service';
import { Borrower } from '../models/borrower-model';
import { Originator } from '../models/originator.model';
import { BorrowerAppStatus } from '../models/borrower-app-status.model';
import { delay, map } from 'rxjs/operators';
import { LoanType } from '../models/loan-type-model';
import { FeatureFlags } from '../models/feature-flags.model';
import { CheckBorrowerExistenceRequest, CreateBorrowerAccountMortgageRequest } from '../models/create-borrower-account-mortgage-request.model';
import { CreateApplicationResponse } from '../models/create-application-response.model';
import { HousingExpense } from '../models/housing-expense.model';
import { Address } from "../models/address-model";
import { CreateApplicationRequest } from '../models/create-application-request.model';
import { GenerateEConsentRequest } from '../models/generate-e-consent-request.model';
import { GenerateEConsentResponse } from '../models/generate-e-consent-response.model';
import { SubjectProperty } from '../models/subject-property.model';
import { Declarations } from '../models/declarations-model';
import { ReferralSource } from '../models/referral-source.model';
import { PricedProduct, PricingResponse, ProductSearchResult } from '../models/pricing/pricing-response.model';
import { ProductSearchRequest } from '../models/pricing/product-search-request-info.model';
import { AssignPricingRequestModel } from '../models/pricing/assign-pricing-request.model';
import { MortgageTerm } from '../models/mortgage-term.model';
import { LoanPurpose } from '../models/enums/loan-purpose.enum';
import { TransactionDetail } from '../models/transaction-detail.model';
import { PurchaseCredit } from '../models/purchase-credit.model';
import { CreditInquiryDetails, CreditInquiryDetailsEntry } from '../models/credit-inquiry-details.model';
import { BorrowerCreditScores } from '../models/borrower-credit-scores.model';
import { ResidencyBasis } from '../models/enums/residency-basis.enum';
import { ResidencyAddress } from '../models/residency-address.model';
import { ResidencyType } from '../models/enums/residency-type.enum';
import { Demographics } from '../models/demographics-model';
import { CompanyInfo } from '../models/company-info.model';

const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'auth-req': 'true', 'X-Requested-With': 'XMLHttpRequest' });

const appStatusKey = "appStatus";

@Injectable()
export class MortgageApplicationService {

  private _mortgageApplication: MortgageApplication;

  constructor(
    private readonly _http: HttpClient,
    private readonly _environmentService: EnvironmentService,
    private readonly _dataService: DataService) {
    this._mortgageApplication = new MortgageApplication();
  }

  get mortgageApplication(): MortgageApplication {
    return this._mortgageApplication;
  }

  getOriginators = (companyGuid: string, branchGuid?: string): Observable<Originator[]> => {
    const url = `${this._environmentService.apiInfo.apiBaseUrl}/api/PublicApi/GetUserListPrimaryRole/${companyGuid}`;
    let query = '';

    if (branchGuid) {
      query += `?branchGuid=${branchGuid}`;
    }
    return <Observable<Originator[]>>this._http.get(url + query, { headers: headers });
  }

  getOriginator = (companyGuid: string, userGuid: string): Observable<Originator | undefined> => {
    return this.getOriginators(companyGuid).pipe(
      map((originators: Originator[]) => {
        let originator = originators.find(i => i.userId == userGuid);
        return originator;
      }
      ));
  }

  getLoanPurposes = (companyGuid: string): Observable<any> => {
    return this._http.get(this._environmentService.apiInfo.apiBaseUrl + `/api/PublicApi/GetLoanPurposes/${companyGuid}`,
      {
        headers: headers
      });
  }

  getMortgageCalculationDetails = (mortgageId: number): Observable<MortgageCalculationDetails> => {
    const url = `api/mortgages/${mortgageId}/calculation-detail`;
    return this._dataService.get(url);
  }

  redoMortgageCalculationDetails = (mortgage: MortgageApplication, requestedLtv: number | null = null): Observable<MortgageCalculationDetails> => {
    let url = `api/mortgages/calculation-detail`;
    if (requestedLtv) {
      url += `?requestedLtv=${requestedLtv}`;
    }
    return this._dataService.post(url, mortgage);
  }

  inviteToCompleteAppForCoborrower = (mortgageId: number, applicationId: number, borrowerId: number): Observable<any> => {
    const url = `api/online-app/${mortgageId}/borrowers/${borrowerId}/invite-to-complete-online-app?applicationId=${applicationId}`;
    return this._dataService.post(url, {});
  }

  mergeCoborrower = (loanId: number, borrowerContactId: number): Observable<any> => {
    const url = `api/online-app/${loanId}/SetAllowPrimaryToSatisfyFlagOnLoan/${borrowerContactId}/true`;
    return this._dataService.post(url, {});
  }

  getLoanTypes = (appId: number): Observable<LoanType[]> => {
    const url = `/api/online-app/loantypes/${appId}`;
    return <Observable<LoanType[]>>this._dataService.get(url);
  }

  saveLoanType = (appId: number, loanTypeId: string) => {
    const url = `/api/online-app/loantype/${appId}/${loanTypeId}`;
    return this._dataService.post(url, {});
  }

  getAppStatus = (appId: number, mortgageId: number, borrowerId: number): Observable<any | undefined> => {
    const url = `api/online-app/oav3-status/${appId}/${mortgageId}/${borrowerId}`;
    return this._dataService.get(url);
  }

  getLicenseStates = (appId: number): Observable<string[]> => {
    const url = `api/online-app/license-states/${appId}`;
    return this._dataService.get(url);
  }

  saveAppStatus = (appId: number, mortgageId: number, borrowerId: number, appStatus: BorrowerAppStatus): Observable<any> => {
    const url = `api/online-app/oav3-status/${appId}/${mortgageId}/${borrowerId}`;
    const payLoad = {
      statusDataJson: JSON.stringify(appStatus.details),
      currentStatus: appStatus.currentStatus,
      lastSuccessfulStatus: appStatus.lastSuccessfulStatus
    }
    return this._dataService.post(url, payLoad);
  }

  getAssetsByBorrowerId = (borrowerId: number): Asset[] => {
    let ownedAssets: Asset[] = [];
    for (var i = 0; i < this._mortgageApplication.assets.length; i++) {
      let asset = this._mortgageApplication.assets[i];
      if (asset) {
        for (var j = 0; j < asset.owningBorrowerIds!.length; j++) {
          if (asset.owningBorrowerIds![j] === borrowerId) {
            ownedAssets.push(asset);
          }
        }
      }
    }
    return ownedAssets;
  }

  saveMortgage = (mortgage?: MortgageApplication): Observable<MortgageApplication> => {
    if (!mortgage) {
      mortgage = this._mortgageApplication;
    }
    const url = `api/mortgages/urla2020/${mortgage.mortgageId}`;
    return this._dataService.post(url, mortgage).pipe(map(result => {
      this._mortgageApplication = result;
      return result;
    }))
  }

  saveLeadSource = (loanId: number, leadSource: string) => {
    const encodedLeadSource = encodeURIComponent(leadSource);
    const url = `api/online-app/update-leadsource/${loanId}?leadSource=${encodedLeadSource}`;
    return this._dataService.post(url, {});
  }

  removeBorrower = (applicationId: number, borrowerId: number): Observable<any> => {
    const url = `api/Loan/${applicationId}/RemoveBorrowerFromLoan/${borrowerId}`;
    return this._dataService.post(url, {});
  }

  getConfig = (applicationId: number): Observable<FeatureFlags> => {
    const url = `api/online-app/config/${applicationId}`;
    return this._dataService.get(url);
  }

  getReferralAgents = (applicationId: number): Observable<ReferralSource[]> => {
    const url = `api/online-app/config/${applicationId}/referral-agents`;
    return this._dataService.get(url);
  }

  getCompanyInfo = (companyGuid: string): Observable<CompanyInfo> => {
    const url = `api/online-app/company-info/${companyGuid}`;
    return this._dataService.get(url);
  }

  getMortgage = (applicationId: number, processVoaImport: boolean = false): Observable<MortgageApplication> => {
    let url = `api/mortgages/urla2020?applicationId=${applicationId}`;
    if (processVoaImport) {
      url += `&processVoaImport=${processVoaImport}`;
    }
    return this._dataService.get(url).pipe(map(response => {
      this._mortgageApplication = response;
      if (!response) {
        this._mortgageApplication = new MortgageApplication();
      }

      if (!this._mortgageApplication.proposedHousingExpense) {
        this._mortgageApplication.proposedHousingExpense = new HousingExpense();
      }
      if (!this._mortgageApplication.subjectProperty) {
        this._mortgageApplication.subjectProperty = new SubjectProperty();
      }
      if (!this._mortgageApplication.borrowers) {
        this._mortgageApplication.borrowers = [];
      }
      this.mortgageApplication.borrowers.forEach(borrower => {
        if (!borrower.declarations) {
          borrower.declarations = new Declarations();
        }
      })
      return this._mortgageApplication;
    }));
  }

  getInquiryCount = (mortgageApplication: MortgageApplication): number => {
    let sum: number = 0;
    for (var borrower of mortgageApplication.borrowers) {
      if (borrower.creditInquiry) {
        sum = sum + borrower.creditInquiry.entries.length;
      }
    }
    return sum;
  }

  getCoBorrowers = (mortgageApplication: MortgageApplication): Borrower[] => {
    return mortgageApplication.borrowers.slice(1);
  }

  saveLoanApplication = (request: CreateBorrowerAccountMortgageRequest): Observable<CreateApplicationResponse> => {
    return this._dataService.postWithoutAuth(`api/online-app/${request.companyGuid}/CreateBorrowerAndApplicationUrla2020`, request);
  }

  saveLoanApplicationNonAnonymous = (request: CreateBorrowerAccountMortgageRequest): Observable<CreateApplicationResponse> => {
    return this._dataService.post(`api/online-app/${request.companyGuid}/CreateBorrowerAndApplicationUrla2020`, request);
  }

  checkIfBorrowerExists = (companyGuid: string, borrowerEmail: string) => {
    const request: CheckBorrowerExistenceRequest = {
      companyGuid: companyGuid,
      emailAddress: borrowerEmail
    }
    const url = `api/online-app/${companyGuid}/checkforborrower`;
    return this._dataService.post(url, request);
  }

  submitApplication = (applicationId: number): Observable<any> => {
    const url = `api/online-app/submit-app/${applicationId}`;
    return this._dataService.post(url, {});
  }

  setSubjectProperty = (address: Address): void => {
    this._mortgageApplication.subjectProperty.address1 = address.address1;
    this._mortgageApplication.subjectProperty.city = address.city;
    this._mortgageApplication.subjectProperty.state = address.state;
    this._mortgageApplication.subjectProperty.zipCode = address.zipCode;
    this._mortgageApplication.subjectProperty.county = address.county;
    this._mortgageApplication.subjectProperty.presentValue = address.presentValue;
  }

  createApplication = (companyGuid: string, req: CreateApplicationRequest): Observable<CreateApplicationResponse> => {
    const url = `api/online-app/${companyGuid}/CreateApplication`;
    return this._dataService.post(url, req);
  }

  generateEConsentDocument = (applicationId: number, req: GenerateEConsentRequest): Observable<GenerateEConsentResponse> => {
    const url = `api/online-app/generateEConsentDocument/${applicationId}`;
    return this._dataService.post(url, req);
  }

  getBorrowerPortalUrl = (): Observable<string> => {
    const url = `api/online-app/urls/borrower-portal`;
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this._dataService.get(url, false, { headers: headers, responseType: 'text' });
  }

  resetMortgage = () => {
    this._mortgageApplication = new MortgageApplication();
  }

  assignProductToApplication = (applicationId: number, assignPricingRequest: AssignPricingRequestModel): Observable<any> => {
    const url = `api/online-app/pricing/${applicationId}/assign`;
    return this._dataService.post(url, assignPricingRequest);
  }

  searchProducts = (applicationId: number): Observable<PricingResponse> => {
    const url = `api/online-app/pricing/${applicationId}/search`;
    return this._dataService.post(url, {});
  };

  repriceLoan = (applicationId: number, request: ProductSearchRequest): Observable<PricingResponse> => {
    const url = `api/online-app/pricing/${applicationId}/reprice`;
    return this._dataService.post(url, request);
  }

  getProductPricingDetails = (
    searchRequest: ProductSearchRequest,
    vendor: string,
  ): Observable<PricedProduct> => {
    const url = `api/online-app/vendor/${vendor}/search/products/detail`;
    return this._dataService.post(url, searchRequest);
  };

  pullMortgageFromLead = (companyGuid: string, leadGuid: string, last4Ssn: string, zipCode: string): Observable<MortgageApplication> => {
    const url = `api/online-app/${companyGuid}/prefill-from-lead/${leadGuid}?last4Ssn=${last4Ssn}&zipCode=${zipCode}`;
    return this._dataService.post(url, {}).pipe(map(result => {
      this._mortgageApplication = result;
      return result;
    }))
  }

  searchProductsFake = (applicationId: number): Observable<PricingResponse> => {
    const product1 = {
      isEligible: true,
      rateSheetId: '3/25/2024 2:18:54 PM',
      priceDate: '2024-03-25T14:18:54.480Z',
      quotes: [
        {
          lockPeriod: 30,
          adjustedRate: 7.25,
          adjustedPrice: 95.293,
          apr: 7.472,
          principalAndInterest: 3832.64,
          monthlyMi: 0.0,
          totalPayment: 3832.64,
          totalClosingCost: 26445.0,
          discountDollars: 26445.0,
          discountPercent: 4.707,
          rebateDollars: 0.0,
          totalFeesDollars: 1789.0,
          thirdPartyFeesDollars: 1290.0
        },
      ],
      productId: '53408041',
      productName: 'Citibank, N.A. - Correspondent -  VA 30 Yr Fixed GNMA II',
      priceStatus: 'Available',
      investorId: '128805',
      investor: 'Citibank, N.A. - Correspondent - ',
      amortizationTerm: 360,
      amortizationType: 'FixedRate',
      armMargin: 0.0,
      loanTerm: 360,
      loanType: 'VA',
      calculatedValues: {},
    };

    const product2 = {
      isEligible: true,
      rateSheetId: '3/25/2024 2:18:54 PM',
      priceDate: '2024-03-25T14:18:54.480Z',
      quotes: [
        {
          lockPeriod: 30,
          adjustedRate: 7.25,
          adjustedPrice: 95.293,
          apr: 7.472,
          principalAndInterest: 6666.6,
          monthlyMi: 0.0,
          totalPayment: 3832.64,
          totalClosingCost: 26445.0,
          discountDollars: 26445.0,
          discountPercent: 4.707,
          rebateDollars: 0.0,
        },
      ],
      productId: '53408042',
      productName: 'U.S. Bank National Association - Correspondent - VA 30 Yr Fixed',
      priceStatus: 'Available',
      investorId: '128805',
      investor: 'Citibank, N.A. - Correspondent - ',
      amortizationTerm: 360,
      amortizationType: 'FixedRate',
      armMargin: 0.0,
      loanTerm: 360,
      loanType: 'VA',
      calculatedValues: {},
    };

    const searchResults = {
      products: [product1, product2],
    };

    const pricingReponse = {
      productSearchRequest: {
        loanInformation: {
          baseLoanAmount: 250000,
        },
        propertyInformation: {
          appraisedValue: 300000,
        }
      },
      productSearchResult: searchResults
    };

    // Simulate a delay of 3 seconds
    return of(pricingReponse).pipe(delay(2000)) as Observable<PricingResponse>;
  };

  private generateFakeMortgage = (): MortgageApplication => {
    let mortgageApplication = new MortgageApplication();
    mortgageApplication.userGuid = "unassigned";
    mortgageApplication.borrowers = [];
    let borrower = new Borrower();
    borrower.borrowerId = 12345;
    borrower.firstName = "Mark";
    borrower.lastName = "Spencer";
    borrower.primaryEmail = "mark.spencer@testmail.com";
    borrower.socialSecNum = '666-66-6666';
    borrower.dateOfBirth = '01/01/1977';
    borrower.languagePreference = 'English';

    borrower.declarations = new Declarations();
    borrower.declarations.residenceStatus = "USCitizen";
    borrower.maritalStatus = "Single";
    borrower.phoneNumber = "(555)5555555";
    borrower.mobilePhone = "(555)5555555";

    borrower.residencyAddresses = [];
    let residencyAddress = new ResidencyAddress(ResidencyType.PresentAddress);
    residencyAddress.address = new Address();
    residencyAddress.address.address1 = "123 Any St.";
    residencyAddress.address.city = "San Diego";
    residencyAddress.address.state = "ca";
    residencyAddress.address.zipCode = "92128";

    residencyAddress.durationYears = 6;
    residencyAddress.durationMonths = 11;
    residencyAddress.rent = 3500;
    residencyAddress.residencyBasis = ResidencyBasis.Own;

    borrower.residencyAddresses.push(residencyAddress);

    let borrowerCreditScores: BorrowerCreditScores[] = [];
    let scores = new BorrowerCreditScores();
    scores.borrowerId = borrower.borrowerId;
    scores.borrowerName = "Mark Spencer";
    scores.equifax = 750;
    scores.experian = 678;
    scores.transUnion = 708;
    scores.date = new Date("01/01/2021");
    borrowerCreditScores.push(scores);

    let creditInquiry = new CreditInquiryDetailsEntry()
    creditInquiry.newDebtAcquired = false;
    creditInquiry.fullName = "ABC Bank";
    creditInquiry.reason = "2";
    creditInquiry.creditInquiryDate = "01/01/2019";
    borrower.creditInquiry = new CreditInquiryDetails();
    borrower.creditInquiry.entries = [];
    borrower.creditInquiry.entries.push(creditInquiry);

    mortgageApplication.transactionDetail = new TransactionDetail();
    mortgageApplication.transactionDetail.purchasePriceAmount = 540000;
    let purchaseCredit = new PurchaseCredit();
    mortgageApplication.transactionDetail.purchaseCredits = [purchaseCredit];
    mortgageApplication.transactionDetail.purchaseCredits[0].purchaseCreditAmount = 200000;

    mortgageApplication.subjectProperty = new SubjectProperty();
    mortgageApplication.subjectProperty.downPaymentSource = "CashOnHand";
    mortgageApplication.subjectProperty.purposeOfLoan = LoanPurpose.Purchase;
    mortgageApplication.subjectProperty.address1 = '123 Any St.';
    mortgageApplication.subjectProperty.city = 'San Diego';
    mortgageApplication.subjectProperty.state = 'ca';
    mortgageApplication.subjectProperty.zipCode = '92128';
    mortgageApplication.subjectProperty.presentValue = 450000;

    mortgageApplication.mortgageTerm = new MortgageTerm();
    mortgageApplication.mortgageTerm.amount = 250000;
    mortgageApplication.mortgageTerm.mortgageAppliedFor = 'Conventional';

    mortgageApplication.extension.hasSignedPurchaseAgreement = false;

    borrower.declarations.haveCompletedShortSale = false;
    borrower.declarations.haveConveyedTitleInLieuOfForeclosure = false;
    borrower.declarations.haveJudgement = false;
    borrower.declarations.haveLawsuit = false;
    borrower.declarations.havePropertiesOwnership = false;
    borrower.declarations.haveRelationshipWithSeller = false;
    borrower.declarations.haveTaxDebt = false;
    borrower.declarations.occupyProperty = false;
    borrower.declarations.propertyHasLienWithCleanEnergyProgram = false;
    borrower.declarations.heldTitleHow = "0";
    borrower.declarations.borrowedDownPayment = false;
    borrower.declarations.coSignedLoan = false;
    borrower.declarations.beenForclosed = false;
    borrower.declarations.applyingForOtherMortgage = false;
    borrower.declarations.applyingForNonMortgageCredit = false;
    borrower.declarations.declaredBankruptcy = false;

    borrower.governmentMonitors = new Demographics();

    mortgageApplication.borrowers.push(borrower);

    mortgageApplication.realEstateOwned = [];
    this._mortgageApplication = mortgageApplication;
    return mortgageApplication;
  }
}


