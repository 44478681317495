import { Component, Injector, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Borrower } from '../../../models/borrower-model';
import { ErrorMessage } from '../../../models/error-message.model';
import { MortgageApplicationService } from '../../../services/mortgage-application.service';
import { BorrowerInfoMiniComponent } from '../../borrower-info-mini/borrower-info-mini.component';
import { WizardStepComponentBase } from '../wizard-step-base.component';
declare const Swal: any;

@Component({
  selector: 'create-multiple-coborrowers',
  templateUrl: 'create-multiple-coborrowers.component.html',
  styleUrls: ['create-multiple-coborrowers.component.scss']
})

export class CreateMultipleCoBorrowersComponent extends WizardStepComponentBase<any> {

  @ViewChildren('borrowerInfo') borrowerInfoComponents: QueryList<BorrowerInfoMiniComponent> | undefined;

  coBorrowers: Borrower[] = [];

  borrowerSettings: Map<number, boolean> = new Map<number, boolean>();

  private _invalidBorrowerInfoComponent: BorrowerInfoMiniComponent;

  constructor(private readonly _mortgageApplicationService: MortgageApplicationService,
    private readonly _injector: Injector) {
    super(_injector);
    this.saveMortgageApplicationBeforeNextStep = true;
    this.coBorrowers = this._mortgageApplicationService.getCoBorrowers(this.mortgageApplication);
    this.borrowerSettings = this.wizardFlowService.context.borrowerSettings;
  }

  onNextClicked() {
    this._invalidBorrowerInfoComponent = null;
    let valid = true;
    if (this.borrowerInfoComponents) {
      for (let borrowerinfo of this.borrowerInfoComponents) {
        valid = borrowerinfo.validate() && valid;
        if (!valid && !this._invalidBorrowerInfoComponent) {
          this._invalidBorrowerInfoComponent = borrowerinfo;
        }
      }
    }
    if (valid) {
      if (this.wizardFlowService.isEditEnabled) {
        super.onNextClicked();
        return;
      }

      const borrowersWithDuplicateEmails = this.findPeopleWithDuplicateEmails();
      if (borrowersWithDuplicateEmails.length) {
        const noticeHtmlForBorrowers = this.prepareHtmlForBorrowersWithSameEmails(borrowersWithDuplicateEmails);
        if (noticeHtmlForBorrowers.length) {

          let html = `<div class='mb-4'>The following borrowers do not have unique e-mails. Please give a unique e-mail for each borrower.</div>`;
          noticeHtmlForBorrowers.forEach(b => {
            html += b;
          })

          const swalConfig = {
            title: 'Warning!',
            html: html,
            icon: 'warning',
            width: '700px',
          }
          Swal.fire(
            swalConfig
          ).then((result) => {

          });
          return;
        }
      }

      this.saveMortgageApplicationBeforeNextStep = false;
      this.startSpinner();

      this.fixBorrowerGroupingsWithinTheApplication();

      this.wizardFlowService.saveMortgage().subscribe(result => {
        let calls: Observable<any>[] = [];
        if (this.borrowerInfoComponents) {
          for (let borrowerinfo of this.borrowerInfoComponents) {
            const insertedBorrower = result.borrowers.find((x: any) => x.firstName === borrowerinfo.borrower.firstName && x.lastName === borrowerinfo.borrower.lastName && x.primaryEmail === borrowerinfo.borrower.primaryEmail);
            const isApplyingForBorrower = this.wizardFlowService.context.borrowerSettings.get(borrowerinfo.borrower.borrowerId);
            if (isApplyingForBorrower !== null && isApplyingForBorrower !== undefined) {
              this.wizardFlowService.context.borrowerSettings.delete(borrowerinfo.borrower.borrowerId);
              this.wizardFlowService.context.borrowerSettings.set(insertedBorrower.borrowerId, isApplyingForBorrower);
            }
            if (!borrowerinfo.mainBorrowerWillApplyForMe) {
              if (insertedBorrower) {
                borrowerinfo.setBorrowerIdContactId(insertedBorrower.borrowerId, insertedBorrower.contactId);
              }
              let borrowerInviteToCompleteAppCall = this.mortgageApplicationService.inviteToCompleteAppForCoborrower(this.mortgageApplication.mortgageId,
                this.mortgageApplication.applicationId, borrowerinfo.borrower.borrowerId!);
              calls.push(borrowerInviteToCompleteAppCall);
            } else {
              // Merge borrower needs to be called here for the borrower
              if (insertedBorrower) {
                let mergeBorrowerCall = this.mortgageApplicationService.mergeCoborrower(this.mortgageApplication.applicationId,
                  insertedBorrower.contactId!);
                calls.push(mergeBorrowerCall);
              }
            }
          }
          const coBorrIds = [];
          const nonCoBorrIds = [];
          this.wizardFlowService.context.borrowerSettings.forEach((value, key, map) => {
            if (value) {
              coBorrIds.push(key);
            } else {
              nonCoBorrIds.push(key);
            }
          });
          this.wizardFlowService.context.borrowerAppStatus.details.coBorrowerIds = coBorrIds;
          this.wizardFlowService.context.borrowerAppStatus.details.nonCoBorrowerIds = nonCoBorrIds;
        }
        if (calls.length > 0) {
          forkJoin(calls).subscribe(
            (result) => {
              this.stopSpinner();
              super.onNextClicked();
            },
            (error) => {
              this.handleError(error);
            }
          );
        } else {
          super.onNextClicked();
        }
      }, error => {
        this.handleError(error);
      });
    } else {
      this._invalidBorrowerInfoComponent.scrollToFirstInvalid();
    }
  }

  private handleError = (error: any) => {
    this.stopSpinner();
    let errorMessage = new ErrorMessage("An error occurred while saving the mortgage", error.error.message);
    this.showError(errorMessage);
    window.scroll(0, 0);
  }

  private findPeopleWithDuplicateEmails = (): Borrower[] => {
    const borrowersWithSameEmails: Borrower[] = [];
    this.mortgageApplication.borrowers.forEach(borrower => {
      const peopleWithSameEmail = this.mortgageApplication.borrowers.filter(b => b.primaryEmail ===
        borrower.primaryEmail);
      if (peopleWithSameEmail.length > 1) {
        borrowersWithSameEmails.push(borrower);
      }
    });
    return borrowersWithSameEmails;
  }

  private prepareHtmlForBorrowersWithSameEmails = (borrowers: Borrower[]): string[] => {
    const borrowersWithoutEmploymentHtml: string[] = [];
    borrowers.forEach(borrower => {
          borrowersWithoutEmploymentHtml.push(`
          <div class='row mb-2' style='max-width: 350px; margin: 0 auto'>
            <div class='col-12'><strong>${this.utilsService.getPersonsFullName(borrower)}</strong></div>
          <div>`)
        }
    );
    return borrowersWithoutEmploymentHtml;
  }

  private fixBorrowerGroupingsWithinTheApplication = () => {
    let primaryBorrower = this.mortgageApplication.borrowers.find(b => b.isPrimary);
    if (!primaryBorrower) {
      primaryBorrower = this.currentBorrower;
      this.currentBorrower.isPrimary = true;
    }
    if (!primaryBorrower.printApplicationIndex) {
      primaryBorrower.printApplicationIndex = 1;
    }
    let printApplicationIndex = primaryBorrower.printApplicationIndex;
    this.coBorrowers.forEach(coBorrower => {
      const coBorrowerWithinApplication = this.mortgageApplication.borrowers.find(borrower => borrower.primaryEmail === coBorrower.primaryEmail);
      if (coBorrowerWithinApplication) {
        if (this.borrowerSettings.get(coBorrower.borrowerId)) {
          coBorrowerWithinApplication.jointWithBorrowerId = primaryBorrower.borrowerId;
          coBorrowerWithinApplication.printApplicationIndex = primaryBorrower.printApplicationIndex;
        } else {
          printApplicationIndex++;
          coBorrowerWithinApplication.jointWithBorrowerId = null;
          coBorrowerWithinApplication.printApplicationIndex = printApplicationIndex;
        }
      }
    })
  }
}
