import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SvgIconComponentFactory } from 'projects/shared/svg-icons/svg-icon-component-factory';
import { SvgIconHostDirective } from 'projects/shared/svg-icons/svg-icon-host.directive';
import { TypeOfIncome } from '../../../models/enums/type-of-income.enum';
import { IncomeItem } from '../../../models/income-item-model';

@Component({
  selector: 'income-item',
  templateUrl: 'income-item.component.html',
})
export class IncomeItemComponent implements OnInit {
  @ViewChild(SvgIconHostDirective, { static: true })
  svgIconHost!: SvgIconHostDirective;

  @Input()
  income!: IncomeItem;

  incomeName: string = '';

  @Output() deleteClicked = new EventEmitter<IncomeItem>();
  @Output() editClicked = new EventEmitter<IncomeItem>();

  deleteAttempted: boolean = false;

  constructor(
    private readonly _svgIconFactory: SvgIconComponentFactory,
    private _componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.loadIcon();
    this.incomeName = this.getIncomeName();
  }

  onDeleteClicked = () => {
    this.deleteAttempted = true;
  };

  onDeleteConfirmed = () => {
    this.deleteAttempted = false;
    this.deleteClicked.emit(this.income);
  };

  onDeleteAborted = () => {
    this.deleteAttempted = false;
  };

  onEditClicked = () => {
    this.editClicked.emit(this.income);
  };

  private loadIcon = () => {
    const iconName = this.getIconName();
    if (!iconName) {
      return;
    }
    const svgIconComponent = this._svgIconFactory.getSvIconComponent(iconName);
    if (!svgIconComponent) {
      return;
    }
    const componentFactory =
      this._componentFactoryResolver.resolveComponentFactory(svgIconComponent);

    const viewContainerRef = this.svgIconHost.viewContainerRef;
    viewContainerRef.clear();

    viewContainerRef.createComponent(componentFactory);
  };

  private getIncomeName(): string {
    if (this.income.name && this.income.name.length > 0) {
      return this.income.name;
    }
    return this.income.type;
  }

  private getIconName(): string {
    if (this.income.type == TypeOfIncome.MilitaryBasePay) {
      return 'duotone-star';
    }
    if (this.income.type == TypeOfIncome.Base) {
      return 'duotone-shirt';
    }
    if (this.income.type == TypeOfIncome.ContractBasis) {
      return 'duotone-tools';
    }
    if (this.income.type == TypeOfIncome.NetRentalIncome) {
      return 'duotone-home';
    }
    if (this.income.type == TypeOfIncome.SocialSecurity) {
      return 'stockholm-bank';
    }
    if (this.income.type == TypeOfIncome.Pension) {
      return 'duotone-dollar';
    }
    if (this.income.type == TypeOfIncome.Disability) {
      return 'duotone-star';
    }
    if (this.income.type == TypeOfIncome.SelfEmploymentIncome) {
      return 'duotone-safe';
    }
    return 'duotone-wallet';
  }
}
