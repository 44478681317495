import { IDecideNextStep } from "../../services/wizard/next-step-decider/next-step-decider.interface";
import { CreateMultipleCoBorrowersComponent } from "../../wizard/wizard-steps/create-multiple-coborrowers/create-multiple-coborrowers.component";
import { WizardStepBase } from "./wizard-step-base.model";

export class CreateCoborrowersStep extends WizardStepBase {
  constructor(id: string, name: string, path: string, nextStepDecider?: IDecideNextStep) {
    super(id, name, path, nextStepDecider);
    this.component = CreateMultipleCoBorrowersComponent;
  }
}
