import { Component, Input, OnInit } from '@angular/core';
import { EnvironmentService } from 'projects/shared/services/environment.service';
import { MortgageApplicationService } from '../../services/mortgage-application.service';

@Component({
  selector: 'application-submitted',
  templateUrl: 'application-submitted.component.html'
})

export class ApplicationSubmittedComponent implements OnInit {

  @Input()
  heading: string = "Welcome!";

  @Input()
  message1: string = "Your application has already been submitted.";

  @Input()
  message2: string = "Please, click the link below to go to your Borrower Portal.";

  @Input()
  showImage: boolean = true;

  constructor(private _environmentService: EnvironmentService,
    private _mortgageApplicationService: MortgageApplicationService) { }

  ngOnInit() { }

  onGotoBorrowerPortalClicked = () => {
    this._mortgageApplicationService.getBorrowerPortalUrl().subscribe(url => {
      setTimeout(() => {
        //redirect
        const authorizationDataKey: string = "authorizationData";
        const authDataObj = JSON.parse(localStorage.getItem(authorizationDataKey)!);
        const token = authDataObj.token;
        let redirectUrl = "https://" + url + "/loans-redirect/"
          + this._mortgageApplicationService.mortgageApplication.applicationId + "?authToken=" + token;
        window.location.href = redirectUrl;
      }, 2000);
    }, error => {

    })
  }
}
