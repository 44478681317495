import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PortalScope } from '../../login/login.component';
import { Constants } from '../../services/constants';
import { PortalServiceFactory } from '../../services/portal-service.factory';
import { BasePortalService } from '../../services/base-portal.service';
import { Observer, firstValueFrom } from 'rxjs';
import { Alert } from 'projects/shared/models/alert.model';
import { AlertType } from 'projects/shared/models/alert-type.enum';
import { EsignOrder, ValidateESignPropertyInfoResponse } from '../../models/e-sign/validate-esign-property-info.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CreateSignerViewResponse } from '../../models/e-sign/create-signer-view-response.model';
import { OpenSignerViewRequest } from '../../models/open-signer-view-request.model';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import * as _ from 'lodash';

@Component({
  selector: 'e-sign-via-token',
  templateUrl: 'e-sign-via-token.component.html',
  styleUrls: ['./e-sign-via-token.component.scss'],
})
export class ESignViaTokenComponent implements OnInit {
  @ViewChild('loanValidationForm')
  loanValidationForm: NgForm;

  protected userScope: PortalScope = PortalScope.Borrower;

  protected alert: Alert;
  protected loadingMessage: string;

  protected isLoading: boolean = false;
  protected readyToSign: boolean = false;
  protected needEconsent: boolean = false;
  protected needToValidateLoan: boolean = false;

  protected zipCodePlaceHolder = 'XXXXX';
  protected zipCodeMask = '00000';
  protected zipCodePattern = '^[0-9]{5}?$';

  protected ssnPlaceHolder = 'XXXX';
  protected ssnMask = '0000';
  protected ssnPattern = '^[0-9]{4}?$';

  protected subjectPropertyZipCode: string;
  protected borrowerSsn: string;

  protected initialToken: string;

  protected signerViewUrl: SafeResourceUrl;
  protected documentSigningVendor: string;
  protected econsentGiven: boolean = false;

  protected isPropertyZipRequired?: boolean = false;
  protected isLast4DigitsOfSsnRequired?: boolean = false;

  protected esignOrderPackage: ValidateESignPropertyInfoResponse;

  private readonly _portalService: BasePortalService;

  private _indexOfCurrentOrder: number = 0;

  private _ordersToSign: EsignOrder[] = [];

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _portalServiceFactory: PortalServiceFactory,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _authService: AuthService
  ) {
    if (this._activatedRoute.snapshot.url.find((s) => s.path.includes('agent-portal'))) {
      this.userScope = PortalScope.Agent;
    }
    this._portalService = this._portalServiceFactory.createByScope(
      this.userScope
    );

    this._authService.logout();

    this._activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this._activatedRoute.queryParams.subscribe((params) => {
        this.initialToken = params[Constants.token];
        if (this.initialToken) {
          this.confirmEsignToken(this.initialToken);
        } else {
          this.alert = new Alert(
            'There is no token.',
            'There needs to be a token in the e-sign URL.',
            AlertType.Error
          );
        }
      });
    });
  }

  ngOnInit() {
  }

  protected onSubmitClicked() {
    if (!this.isFormValid()) {
      return;
    }
    this.alert = null;
    this.validateLoan();
  }

  protected onEconsentSubmitClicked() {
    if (!this.isFormValid()) {
      return;
    }
    const observer: Observer<any> = {
      next: (result: any) => {
        this.isLoading = false;
        this.needEconsent = false;
        setTimeout(() => {
          this.startEsignProcess();
        });
      },
      error: (err: any) => {
        let errorMessage = 'An error occurred while submitting your e-consent. Please contact your loan officer for assistance.';
        if (err.status == 403) {
          errorMessage =
            'You do not have permission to submit e-consent for this loan.';
        } else {
          // Here, we need to start over
          this.startOverTheProcess();
        }
        this.alert = new Alert(
          'A problem occurred.',
          errorMessage,
          AlertType.Error
        );
      },
      complete: () => { },
    };

    this.isLoading = true;
    this.loadingMessage = 'Submitting E-consent, please wait...';
    const orderThatHasPrivateTokenMatchingInitialOne =
      this._ordersToSign.find(o => o.publicToken.toLowerCase() === this.initialToken.toLowerCase());
    this._portalService
      .acceptEsignConsentForAnonymousEsign(
        this.initialToken,
        encodeURIComponent(orderThatHasPrivateTokenMatchingInitialOne.privateToken),
        this.esignOrderPackage.applicationId,
        this.esignOrderPackage.borrowerId,
        this.esignOrderPackage.mortgageBorrowerId
      )
      .subscribe(observer)
      .add(() => {
        this.isLoading = false;
      });
  }

  private startOverTheProcess = async () => {
    this.needEconsent = false;
    this.readyToSign = false;
    await this.confirmEsignToken(this.initialToken);
  };

  private confirmEsignToken = async (token: string): Promise<void> => {
    this.isLoading = true;
    this.loadingMessage = 'Validating token, please wait...';

    try {
      const response = await firstValueFrom(
        this._portalService.confirmEsignToken(token)
      );
      this.needToValidateLoan =
        response.requiresSsnLastFourDigits ||
        response.requiresSubjectPropertyZip;
      this.isLast4DigitsOfSsnRequired = response.requiresSsnLastFourDigits;
      this.isPropertyZipRequired = response.requiresSubjectPropertyZip;
    } catch (err) {
      this.alert = new Alert(
        'A problem occurred.',
        "An error occurred validating your token. It's either invalid or has expired.",
        AlertType.Error
      );
    }
    this.isLoading = false;
  };

  private validateLoan = () => {
    const observer: Observer<ValidateESignPropertyInfoResponse> = {
      next: (response: any) => {
        this.isLoading = false;
        this.loadingMessage = '';
        this.needToValidateLoan = false;
        this.esignOrderPackage = response;
        let ordersToSign = this.esignOrderPackage.eSignOrderDetail.filter((o) => !!o.publicToken && !!o.privateToken);
        this._ordersToSign = _.orderBy(ordersToSign, ['signingOrder'], ['asc']);
        this.needEconsent = !response.borrowerHasConsented;
        if (!this.needEconsent) {
          this.startEsignProcess();
        } else if (response.eConsentText) {
          this.loadEconsentText(response.eConsentText);
        }
      },
      error: (err: any) => {
        const errorMessage = 'An error occurred while validating your application. Please contact your loan officer for assistance.';
        this.alert = new Alert(
          'A problem occurred.',
          errorMessage,
          AlertType.Error
        );
      },
      complete: () => { },
    };

    this.isLoading = true;
    this.loadingMessage = 'Validating loan, please wait...';
    const last4DigitsOfSsn = this.borrowerSsn?.slice(-4);
    this._portalService
      .validateLoanForAnonymousEsign(
        this.initialToken,
        this.subjectPropertyZipCode,
        last4DigitsOfSsn
      )
      .subscribe(observer)
      .add(() => {
        this.isLoading = false;
      });
  };

  private startEsignProcess = () => {
    if (this._ordersToSign.length > 0) {
      this.gotoEsignView(this._ordersToSign[0]);
    }
  };

  private gotoEsignView = (order: EsignOrder) => {
    const observer: Observer<CreateSignerViewResponse> = {
      next: (response: any) => {
        this.documentSigningVendor = response.documentSigningVendor;
        this.signerViewUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(
          response.signerViewUrl
        );
        this.readyToSign = true;
        window.addEventListener(
          'message',
          (event) => {
            if (event.data.shouldCloseEsignDocIframe) {
              setTimeout(() => {
                this._indexOfCurrentOrder++;
                if (this._indexOfCurrentOrder < this._ordersToSign.length) {
                  this.gotoEsignView(this._ordersToSign[this._indexOfCurrentOrder]);
                }
              }, 2000);
            }
          },
          false
        );
      },
      error: (err: any) => {
        let errorMessage = 'The data entered does not match our records, please try again.';
        if (err.status == 403) {
          errorMessage =
            'You do not have permission to submit e-consent for this loan.';
        } else {
          // Here, we need to start over
          this.startOverTheProcess();
        }
        this.alert = new Alert(
          'A problem occurred.',
          errorMessage,
          AlertType.Error
        );
      },
      complete: () => { },
    };

    const portalUrlSegment =
      this.userScope == PortalScope.Borrower
        ? 'borrower-portal'
        : 'agent-portal';
    const returnUrl = `${window.location.protocol}//${window.location.host}/${portalUrlSegment}/esign-confirmation/${order.publicToken}/${this._indexOfCurrentOrder + 1}/${this._ordersToSign.length}`;

    let request = new OpenSignerViewRequest();
    request.returnUrl = returnUrl;
    request.loanDocTaskId = order.loanDocTaskId;
    request.applicationId = this.esignOrderPackage.applicationId;
    request.borrowerId = this.esignOrderPackage.borrowerId;

    this.isLoading = true;
    this.loadingMessage = 'Fetching esign URL, please wait...';
    this._portalService
      .openEsignerViewForAnonymousEsign(
        order.publicToken,
        encodeURIComponent(order.privateToken),
        order.loanDocTaskId,
        request
      )
      .subscribe(observer)
      .add(() => {
        this.isLoading = false;
      });
  };

  private isFormValid = (): boolean => {
    if (this.loanValidationForm) {
      this.loanValidationForm.form.markAllAsTouched();
      return !this.loanValidationForm.form.invalid;
    }
    return true;
  };

  private loadEconsentText = (econsentText: string) => {
    setTimeout(() => {
      let iframe = document.getElementById('econsent-text-iframe') as HTMLIFrameElement;
      if (!iframe) {
        return;
      }
      let doc = iframe.contentDocument;
      doc.open();
      doc.write(econsentText);
      doc.close();
    });
  }
}
