import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { Borrower } from '../../../models/borrower-model';
import { TypeOfIncome } from '../../../models/enums/type-of-income.enum';
import { Income } from '../../../models/income-model';
import { EnumsService } from '../../../services/enums.service';
import { UtilsService } from '../../../services/utils.service';
import { BorrowerPicker } from '../../borrower-picker/borrower-picker.component';
import { ConfigurableFieldHostComponent } from '../../configurable-field-host.component';

@Component({
  selector: 'simple-income-dialog',
  templateUrl: 'simple-income-dialog.component.html'
})

export class SimpleIncomeDialogComponent extends ConfigurableFieldHostComponent implements OnInit {

  @ViewChild('incomeInfoForm') incomeInfoForm: NgForm | undefined;
  @ViewChild(BorrowerPicker) borrowerPicker: BorrowerPicker | undefined;

  @Input() borrowers: Borrower[] = [];

  @Input()
  public set income(income: Income) {
    this._income = income;
    this._editorIncomeType = this.income.typeOfIncome;
    this._inEditMode = true;
  }

  public get income(): Income {
    return this._income;
  }

  public header: string | undefined;
  public label: string | undefined;

  public get inEditMode(): boolean {
    return this._inEditMode;
  }

  otherIncomeTypes: EnumerationItem[] = [];

  set editorIncomeType(typeOfIncome: TypeOfIncome) {
    this._editorIncomeType = typeOfIncome;
    this._income.typeOfIncome = this._editorIncomeType;
  }

  get editorIncomeType(): TypeOfIncome {
    return this._editorIncomeType;
  }

  private _income: Income;
  private _inEditMode: boolean = false;
  private _editorIncomeType: TypeOfIncome = TypeOfIncome.OtherTypesOfIncome;

  constructor(public activeModal: NgbActiveModal,
    private readonly _utilsService: UtilsService,
    private readonly _enumsService: EnumsService) {
    super();
    this._income = new Income();
    this._income.typeOfIncome = this._editorIncomeType;
    this._income.incomeId = this._utilsService.generateUniqueNumericId();
    this._enumsService.getMortgageEnumerations().subscribe(enums => {
      this.otherIncomeTypes = enums['IncomeType'].filter(t => this.filterIncomesTypesThatAreNotInOtherCategory(t.value));
    });
  }

  onSaveClicked = () => {
    this.incomeInfoForm?.form.markAllAsTouched();
    let borrowerValid = true;
    if (this.borrowerPicker) {
      borrowerValid = this.borrowerPicker.validate();
    }
    if (this.incomeInfoForm?.form.valid && borrowerValid) {
      this.activeModal.close(this._income);
    }
  }

  ngOnInit() {
    if (this._income.incomeId <= 0) {
      if (this.borrowers.length > 0) {
        this._income.borrowerId = this.borrowers[0].borrowerId;
      }
    }
  }

  private filterIncomesTypesThatAreNotInOtherCategory = (incomeType: string): boolean => {
    return !(incomeType == TypeOfIncome.Base ||
      incomeType == TypeOfIncome.Bonus ||
      incomeType == TypeOfIncome.Commissions ||
      incomeType == TypeOfIncome.Pension ||
      incomeType == TypeOfIncome.SocialSecurity ||
      incomeType == TypeOfIncome.NetRentalIncome ||
      incomeType == TypeOfIncome.MilitaryBasePay ||
      incomeType == TypeOfIncome.SelfEmploymentIncome ||
      incomeType == TypeOfIncome.ContractBasis ||
      incomeType == TypeOfIncome.Commissions ||
      incomeType == TypeOfIncome.Overtime);
  }
}
