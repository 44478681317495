<div class="hidden-when-mobile notice d-flex rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 b-4 p-6 mb-4"
[ngClass]="{'border-primary': !deleteAttempted && income.isValid, 'border-danger' : deleteAttempted || !income.isValid, 'border-dashed': !deleteAttempted && income.isValid  }">
  <span style="padding-top:3px" class="svg-icon svg-icon-2x svg-icon-primary me-4 align-items-center mt-1 duotone-home-svg-mobile"
  [ngClass]="{'svg-icon-primary': !deleteAttempted && income.isValid, 'svg-icon-danger' : deleteAttempted || !income.isValid }">
    <ng-template svgIconHost></ng-template>
  </span>
  <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap align-items-center">
    <span class="fs-6 text-gray-600 d-block fw-bold">{{incomeName}}
      <span *ngIf="income.digitallyVerified" class="badge badge-success" style="margin-left: 12px;">Verified</span>
    </span>
    <div class="d-flex justify-content-end align-items-center">
      <div class="position-relative px-4" *ngIf="income.position">
        <label>{{income.isOther ? 'Description' : 'Position'}}: {{income.position}}</label>
      </div>
      <label *ngIf="income.startDate" class="align-items-center px-4">Start Date: {{income.startDate| date}}</label>
      <label class="px-4">Monthly Pay: {{income.monthlyPay| currency}}</label>
      <div class="align-self-center text-nowrap">
        <div class="d-flex" style="margin-top: 4px;" *ngIf="deleteAttempted">
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1" (click)="onDeleteConfirmed()">
            <span class="svg-icon svg-icon-3">
              <stockholm-check-svg-icon></stockholm-check-svg-icon>
            </span>
          </a>
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm" (click)="onDeleteAborted()">
            <span class="svg-icon svg-icon-3">
              <stockholm-close-svg-icon></stockholm-close-svg-icon>
            </span>
          </a>
        </div>
        <div class="d-flex" style="margin-top: 4px;" *ngIf="!deleteAttempted">
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" (click)="onEditClicked()">
            <span class="svg-icon svg-icon-3">
              <duotone-write-svg-icon></duotone-write-svg-icon>
            </span>
          </a>
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" (click)="onDeleteClicked()">
            <span class="svg-icon svg-icon-3">
              <duotone-trash-svg-icon></duotone-trash-svg-icon>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="hidden-when-not-mobile rounded border-primary border border-dashed min-w-lg-600px mb-4"
  [ngClass]="{'border-primary': !deleteAttempted && income.isValid, 'border-danger' : deleteAttempted || !income.isValid, 'border-dashed': !deleteAttempted && income.isValid}"
  style="padding: 5px !important;">
  <span style="padding-top:3px"
    class="svg-icon svg-icon-2x svg-icon-primary me-4 align-items-center mt-1 duotone-home-svg-mobile"
    [ngClass]="{'svg-icon-primary': !deleteAttempted && income.isValid, 'svg-icon-danger' : deleteAttempted || !income.isValid }">
    <ng-template svgIconHost></ng-template>
  </span>
  <div style="width: 100%;">
    <div class="d-flex justify-content-between mx-2">
      <span class="fs-4 text-gray-600 d-block fw-bold mt-3">{{incomeName}}
        <span *ngIf="income.digitallyVerified" class="badge badge-success" style="margin-left: 12px;">Verified</span>
      </span>
      <div class="align-self-center text-nowrap">
        <div class="d-flex" style="margin-top: 4px;" *ngIf="deleteAttempted">
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-success btn-sm me-1"
            (click)="onDeleteConfirmed()">
            <span class="svg-icon svg-icon-3">
              <stockholm-check-svg-icon></stockholm-check-svg-icon>
            </span>
          </a>
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
            (click)="onDeleteAborted()">
            <span class="svg-icon svg-icon-3">
              <stockholm-close-svg-icon></stockholm-close-svg-icon>
            </span>
          </a>
        </div>
        <div class="d-flex" style="margin-top: 4px;" *ngIf="!deleteAttempted">
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            (click)="onEditClicked()">
            <span class="svg-icon svg-icon-3">
              <duotone-write-svg-icon></duotone-write-svg-icon>
            </span>
          </a>
          <a href="javascript:void(0);" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            (click)="onDeleteClicked()">
            <span class="svg-icon svg-icon-3">
              <duotone-trash-svg-icon></duotone-trash-svg-icon>
            </span>
          </a>
        </div>
      </div>
    </div>
    <div class="mt-3 px-2">
      <div *ngIf="income.position">
        <label style="min-width: 6.5rem;"> {{income.isOther ? 'Description' : 'Position'}}: </label>
        <span> {{income.position}} </span>
      </div>
      <div *ngIf="income.startDate">
        <label style="min-width: 6.5rem;"> Start Date: </label>
        <span> {{income.startDate| date}} </span>
      </div>
      <div *ngIf="income.monthlyPay">
        <label style="min-width: 6.5rem;" > Monthly Pay: </label>
        <span> {{income.monthlyPay| currency}} </span>
      </div>
    </div>
  </div>
</div>