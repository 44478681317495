import { AfterViewInit, Component, Injector, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ModalService } from 'projects/shared/modal/modal.service';
import { Borrower } from '../../../models/borrower-model';
import { Employment } from '../../../models/employment-model';
import { CategoryOfIncome } from '../../../models/enums/category-of-income.enum';
import { Income } from '../../../models/income-model';
import { EmploymentAndIncomeType, EmploymentDialogComponent } from '../employment-dialog/employment-dialog.component';
import { IncomeStep } from '../../../models/wizard/income-step.model';
import { SimpleIncomeDialogComponent } from '../simple-income-dialog/simple-income-dialog.component';
import { BorrowerIncomeComponent } from '../../borrower-income/borrower-income.component';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { UtilsService } from '../../../services/utils.service';
import * as _ from 'lodash';
import { TypeOfIncome } from '../../../models/enums/type-of-income.enum';
import { DateTime } from 'luxon';
import { EmploymentTypeEnum } from '../../../models/mortgage.model';
import { MonthsToYearsAndMonthsPipe } from 'projects/shared/pipes/months-to-years-and-months.pipe';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { NgForm } from '@angular/forms';

declare const Swal: any;

const modalOptions: NgbModalOptions = {
  size: "xl",
  backdrop: "static",
  container: "body"
};

@Component({
  selector: 'income-step',
  templateUrl: 'income-step.component.html',
  styleUrls: ['./income-step.component.scss']
})
export class IncomeStepComponent extends WizardStepComponentBase<IncomeStep> implements OnInit, AfterViewInit {

  @ViewChildren('borrowerIncome') borrowerIncomeComponents: QueryList<BorrowerIncomeComponent> | undefined;

  @ViewChild('incomeForm')
  incomeForm: NgForm;

  borrowers: Borrower[] = [];

  mainBorrower!: Borrower;
  coBorrowers: Borrower[] = [];

  protected selectedTypeOfIncome: string;

  protected addingIncome: boolean = false;

  protected typesOfIncome: EnumerationItem[] = [
    new EnumerationItem("Employment (W2)", TypeOfIncome.Base),
    new EnumerationItem("Independent Contractor", TypeOfIncome.ContractBasis),
    new EnumerationItem("Military Pay", TypeOfIncome.MilitaryBasePay),
    new EnumerationItem("Rental", TypeOfIncome.NetRentalIncome),
    new EnumerationItem("Social Security", TypeOfIncome.SocialSecurity),
    new EnumerationItem("Pension", TypeOfIncome.Pension),
    new EnumerationItem("Business/Self Employment", TypeOfIncome.SelfEmploymentIncome),
    new EnumerationItem("Other", TypeOfIncome.OtherTypesOfIncome),
  ]

  private _dateFormat: string = 'MM/dd/yyyy';

  borrowerEmploymentInfoByBorrowerId: Map<number, BorrowerEmploymentInfo> = new Map<number, BorrowerEmploymentInfo>();

  constructor(private readonly _modalService: ModalService,
    private readonly _utilsService: UtilsService,
    private readonly _injector: Injector,
    private readonly _x: MonthsToYearsAndMonthsPipe) {
    super(_injector);
    this.saveMortgageApplicationBeforeNextStep = true;
    this.borrowers = this.mortgageApplication.borrowers;
    this.migrateIncomeCategoriesToIncomeTypes(this.borrowers);
    if (this.wizardFlowService.inCoApplyFlow) {
      this.borrowers = [this.wizardFlowService.context.currentBorrower];
    }
    if (this.currentBorrower) {
      this.mainBorrower = this.currentBorrower;
      this.coBorrowers = this.mortgageApplication.borrowers.filter(b => b.primaryEmail.toLocaleLowerCase() !==
        this.mainBorrower.primaryEmail.toLocaleLowerCase());
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.calculateEmploymentTotalDurationInMonthsForBorrowers();
  }

  onNextClicked() {
    if (this.wizardFlowService.isEditEnabled) {
      super.onNextClicked();
      return;
    }
    if (this.incomeForm) {
      this.incomeForm.form.markAllAsTouched();
      if (!this.incomeForm.form.valid) {
        return;
      }
    }

    if (!this.step.enforceAtLeast2YearsOfEmploymentHistory) {
      const borrowersWithoutEmployment = this.getBorrowersWithoutEmployment();
      if (borrowersWithoutEmployment.length) {
        const noticeHtmlForBorrowers = this.prepareHtmlForBorrowersWithoutEmploymentNotice(borrowersWithoutEmployment);
        if (noticeHtmlForBorrowers.length) {

          let html = `<div class='mb-4'>You have not provided employment information for the following borrowers. Are you sure you'd like to proceed?</div>`;
          noticeHtmlForBorrowers.forEach(b => {
            html += b;
          })

          const swalConfig = {
            showCancelButton: true,
            title: 'Warning!',
            html: html,
            icon: 'question',
            width: '700px',
            confirmButtonText: 'Yes, Proceed',
            cancelButtonText: 'No, I will add them',
          }
          Swal.fire(
            swalConfig
          ).then((result) => {
            if (result.value) {
              this.proceedToNextStep();
            }
          });
        }
      } else {
        this.proceedToNextStep();
      }
    } else {
      this.proceedToNextStep();
    }
  }

  onPensionClicked = () => {
    if (!this.inEditMode) {
      this.showPensionIncomePopup();
    }
  }

  onW2BasedEmploymentClicked = () => {
    if (!this.inEditMode) {
      this.showW2BasedEmploymentPopup();
    }
  }

  onMilitaryPayClicked = () => {
    if (!this.inEditMode) {
      this.showMilitaryPayPopup();
    }
  }

  onIndependentContractorClicked = () => {
    if (!this.inEditMode) {
      this.showIndependentContractorPopup();
    }
  }

  onRentalIncomeClicked = () => {
    if (!this.inEditMode) {
      this.showRentalIncomePopup();
    }
  }

  onSocialSecurityClicked = () => {
    if (!this.inEditMode) {
      this.showSocialSecurityIncomePopup();
    }
  }

  onSelfEmploymentClicked = () => {
    if (!this.inEditMode) {
      this.showBusinessSelfEmploymentPopup();
    }
  }

  onOtherIncomeClicked = () => {
    if (!this.inEditMode) {
      this.showOtherIncomePopup();
    }
  }

  onEmploymentChanged = () => {
    this.calculateEmploymentTotalDurationInMonthsForBorrowers();
  }

  onTypeOfIncomeChanged = () => {
    switch(this.selectedTypeOfIncome) {
      case TypeOfIncome.Base:
        this.showW2BasedEmploymentPopup();
        return;
      case TypeOfIncome.ContractBasis:
        this.showIndependentContractorPopup();
        return;
      case TypeOfIncome.MilitaryBasePay:
        this.showMilitaryPayPopup();
        return;
      case TypeOfIncome.NetRentalIncome:
        this.showRentalIncomePopup();
        return;
      case TypeOfIncome.SocialSecurity:
        this.showSocialSecurityIncomePopup();
        return;
      case TypeOfIncome.Pension:
        this.showPensionIncomePopup();
        return;
      case TypeOfIncome.SelfEmploymentIncome:
        this.showBusinessSelfEmploymentPopup();
        return;
      case TypeOfIncome.OtherTypesOfIncome:
        this.showOtherIncomePopup();
        return;
    }
  }

  onAddIncomeClicked = () => {
    this.addingIncome = true;
  }

  private proceedToNextStep = () => {

    let hasEnoughEmploymentHistory = true;

    const borrowerEmploymentDurationsHtml: string[] = [];

    if (this.step.enforceAtLeast2YearsOfEmploymentHistory) {
      this.mortgageApplication.borrowers.forEach(borrower => {
        if (this.borrowerSetting(borrower) || this.mainBorrower.borrowerId === borrower.borrowerId) {
          if (this.borrowerEmploymentInfoByBorrowerId.has(borrower.borrowerId)) {
            const borrowerEmploymentInfo = this.borrowerEmploymentInfoByBorrowerId.get(borrower.borrowerId);

            if (!borrowerEmploymentInfo.isRetired && borrowerEmploymentInfo.numberOfMonthsEmployed < 24) {
              hasEnoughEmploymentHistory = false;

              const employmentDurationText = this._x.transform(borrowerEmploymentInfo.numberOfMonthsEmployed)
              borrowerEmploymentDurationsHtml.push(`
                <div class='row mb-2'>
                  <div class='col-6'>${borrower.firstName + " " + borrower.lastName}</div>
                  <div class='col-6' style='color:red'>${employmentDurationText}</div>
                <div>`)
            }
          }
        }
      })
    }

    if (!hasEnoughEmploymentHistory) {

      let html = `<div class='mb-4'>You need to provide at least 2 years of employment history for the following borrowers:</div>`;
      borrowerEmploymentDurationsHtml.forEach(b => {
        html += b;
      })

      const swalConfig = {
        title: 'Warning!',
        html: html,
        icon: 'error',
        confirmButtonText: 'OK',
        width: '700px'
      }
      Swal.fire(
        swalConfig
      ).then(() => {
      });
    }

    if (hasEnoughEmploymentHistory && this.isValid()) {
      super.onNextClicked();
    }
  }

  private getBorrowersWithoutEmployment = (): Borrower[] => {
    const borrowersWithoutEmployment: Borrower[] = [];
    this.mortgageApplication.borrowers.forEach(borrower => {
      if (this.borrowerSetting(borrower) || this.mainBorrower.borrowerId === borrower.borrowerId) {
        if (!borrower.employments || !borrower.employments.length) {
          borrowersWithoutEmployment.push(borrower);
        }
      }
    })
    return borrowersWithoutEmployment;
  }

  private prepareHtmlForBorrowersWithoutEmploymentNotice = (borrowers: Borrower[]): string[] => {
    const borrowersWithoutEmploymentHtml: string[] = [];
    borrowers.forEach(borrower => {
          borrowersWithoutEmploymentHtml.push(`
          <div class='row mb-2' style='max-width: 350px; margin: 0 auto'>
            <div class='col-12'><strong>${this.utilsService.getPersonsFullName(borrower)}</strong></div>
          <div>`)
        }
    );
    return borrowersWithoutEmploymentHtml;
  }

  private isBorrowerRetired = (borrower: Borrower): boolean => {
    return borrower.employments.filter(emp => emp.employmentType == EmploymentTypeEnum.CurrentEmployer && emp.employer == "Retired").length > 0;
  }

  private isValid(): boolean {
    let result: boolean = true;
    this.borrowerIncomeComponents?.forEach((borrowerIncomeComponent) => {
      if (!borrowerIncomeComponent.isValid()) {
        result = false;
      }
    });
    return result;
  }

  private migrateIncomeCategoriesToIncomeTypes = (borrowers: Borrower[]) => {
    borrowers.forEach(b => {
      b.nonEmploymentIncomes.forEach(i => {
        if (i.categoryOfIncome && !i.typeOfIncome) {
          i.typeOfIncome = this.fromCategoryToType(i.categoryOfIncome);
        }
      })
    });
  }

  private fromCategoryToType = (category: CategoryOfIncome): TypeOfIncome => {
    switch (category) {
      case CategoryOfIncome.Pension:
        return TypeOfIncome.Pension;
      case CategoryOfIncome.Rental:
        return TypeOfIncome.NetRentalIncome;
      case CategoryOfIncome.IndependentContractor:
        return TypeOfIncome.ContractBasis;
      case CategoryOfIncome.MilitaryPay:
        return TypeOfIncome.MilitaryBasePay;
      case CategoryOfIncome.SocialSecurity:
        return TypeOfIncome.SocialSecurity;
      case CategoryOfIncome.BusinessSelfEmployment:
        return TypeOfIncome.SelfEmploymentIncome;
      case CategoryOfIncome.Other:
        return TypeOfIncome.OtherTypesOfIncome;
      default:
        return TypeOfIncome.OtherTypesOfIncome;
    }
  }

  private getBorrowersAppliedFor() {
    let borrowersAppliedFor: Borrower[] = [];
    borrowersAppliedFor.push(this.currentBorrower);
    this.mortgageApplication.borrowers.forEach((borrower) => {
      let result = this.wizardFlowService.context.borrowerSettings.get(borrower.borrowerId);
      if (result) {
        borrowersAppliedFor.push(borrower);
      }
    });
    return borrowersAppliedFor;
  }

  private showOtherIncomePopup(income?: Income) {
    this.showNonEmploymentBasedIncomeDialog(TypeOfIncome.OtherTypesOfIncome, income);
  }

  private showPensionIncomePopup(income?: Income) {
    this.showNonEmploymentBasedIncomeDialog(TypeOfIncome.Pension, income);
  }

  private showSocialSecurityIncomePopup(income?: Income) {
    this.showNonEmploymentBasedIncomeDialog(TypeOfIncome.SocialSecurity, income);
  }

  private showRentalIncomePopup(income?: Income) {
    this.showNonEmploymentBasedIncomeDialog(TypeOfIncome.NetRentalIncome, income);
  }

  private showBusinessSelfEmploymentPopup(employment?: Employment) {
    this.showEmploymentBasedIncomeDialog(TypeOfIncome.SelfEmploymentIncome, employment);
  }

  private showMilitaryPayPopup(employment?: Employment) {
    this.showEmploymentBasedIncomeDialog(TypeOfIncome.MilitaryBasePay, employment);
  }

  private showIndependentContractorPopup(employment?: Employment) {
    this.showEmploymentBasedIncomeDialog(TypeOfIncome.ContractBasis, employment);
  }

  private showEmploymentBasedIncomeDialog = (typeOfIncome: TypeOfIncome, employment?: Employment) => {
    const modal = this._modalService.show(EmploymentDialogComponent, modalOptions);
    modal.componentInstance.typeOfIncome = typeOfIncome;
    modal.componentInstance.showCountry = true;
    if (employment) {
      modal.componentInstance.employment = employment;
    } else {
      if (typeOfIncome == TypeOfIncome.SelfEmploymentIncome)
        modal.componentInstance.employment.selfEmployed = true;
    }
    modal.componentInstance.fieldsToConfig = this.step.fieldConfig;
    modal.componentInstance.borrowers = (this.wizardFlowService.inCoApplyFlow) ? [this.currentBorrower] : this.getBorrowersAppliedFor();
    modal.result.then((result: EmploymentAndIncomeType) => {
      this.addingIncome = false;
      this.selectedTypeOfIncome = null;
      if (result.typeOfIncome === TypeOfIncome.Disability) {
        // This is not an employment income - add it as non-employment income
        const disability = new Income();
        disability.borrowerId = result.employment.borrowerId;
        disability.incomeId = this._utilsService.generateUniqueNumericId();
        disability.typeOfIncome = TypeOfIncome.Disability;
        disability.monthlyIncome = result.employment.monthlyIncome;
        this.onSaveClickedOnIncomeModalForCreate(disability);
        return;
      }
      this.onSaveClickedOnEmploymentModalForCreate(result.employment, typeOfIncome);
    }, error => { this.addingIncome = false; this.selectedTypeOfIncome = null; });
  }

  private showNonEmploymentBasedIncomeDialog = (typeOfIncome: TypeOfIncome, income?: Income) => {
    const modal = this._modalService.show(SimpleIncomeDialogComponent);
    modal.componentInstance.header = this.getDialogHeader(typeOfIncome);
    modal.componentInstance.label = modal.componentInstance.label = this.getLabel('income.monthlyIncome', 'Monthly Income');
    if (income) {
      modal.componentInstance.income = income;
    }
    modal.componentInstance.editorIncomeType = typeOfIncome;
    modal.componentInstance.fieldsToConfig = this.step.fieldConfig;
    modal.componentInstance.borrowers = (this.wizardFlowService.inCoApplyFlow) ? [this.currentBorrower] : this.getBorrowersAppliedFor();
    modal.result.then(income => {
      this.addingIncome = false;
      this.selectedTypeOfIncome = null;
      this.onSaveClickedOnIncomeModalForCreate(income);
    }, error => {
      this.addingIncome = false;
      this.selectedTypeOfIncome = null;
    })
  }

  private getDialogHeader = (typeOfIncome: TypeOfIncome): string => {
    switch (typeOfIncome) {
      case TypeOfIncome.Pension:
        return 'Pension';
      case TypeOfIncome.NetRentalIncome:
        return 'Rental';
      case TypeOfIncome.SocialSecurity:
        return "Social Security";
      case TypeOfIncome.MilitaryBasePay:
        return "Military Pay";
      case TypeOfIncome.Base:
        return "W2 Employment";
      case TypeOfIncome.SelfEmploymentIncome:
        return "Business/Self Employment";
      case TypeOfIncome.ContractBasis:
        return "Independent Contractor";
      case TypeOfIncome.OtherTypesOfIncome:
        return 'Other';
      default:
        return 'Income';
    }
  }

  private showW2BasedEmploymentPopup(employment?: Employment) {
    this.showEmploymentBasedIncomeDialog(TypeOfIncome.Base, employment);
  }

  private onSaveClickedOnIncomeModalForCreate = (income: Income): void => {
    const borrower = this.borrowers.find(b => b.borrowerId == income.borrowerId);
    if (borrower) {
      borrower.nonEmploymentIncomes.push(income);
      if (this.borrowerIncomeComponents) {
        const borrowerIncomeComponent = this.borrowerIncomeComponents.find(c => c.borrower.borrowerId == income.borrowerId);
        if (borrowerIncomeComponent) {
          borrowerIncomeComponent.refresh();
        }
      }
    }
  }

  private onSaveClickedOnEmploymentModalForCreate = (employment: Employment, typeOfIncome: TypeOfIncome): void => {
    let income: Income = new Income();
    income.incomeId = this._utilsService.generateUniqueNumericId();
    income.monthlyIncome = employment.monthlyIncome || employment.selfEmploymentMonthlyIncomeOrLoss;
    income.typeOfIncome = typeOfIncome;
    income.businessType = employment.businessType;

    employment.incomes.push(income);

    const borrower = this.borrowers.find(b => b.borrowerId == employment.borrowerId);
    if (borrower) {
      borrower.employments.push(employment);
    }

    if (this.borrowerIncomeComponents) {
      const borrowerIncomeComponent = this.borrowerIncomeComponents.find(c => c.borrower.borrowerId == employment.borrowerId);
      if (borrowerIncomeComponent) {
        borrowerIncomeComponent.refresh();
      }
    }
    this.calculateEmploymentTotalDurationInMonthsForBorrowers();
  }

  private calculateEmploymentTotalDurationInMonthsForBorrowers = () => {
    this.mortgageApplication.borrowers.forEach(borrower => {
      let totalDurationOfEmploymentInMonths: number = 0;
      let isRetired: boolean = false;
      if (borrower.employments) {
        borrower.employments.forEach(employment => {
          totalDurationOfEmploymentInMonths += this.calculateEmploymentDurationInMonths(employment);
          isRetired = this.isBorrowerRetired(borrower);
        });
      }
      const borrowerEmployemntInfo: BorrowerEmploymentInfo = {
        numberOfMonthsEmployed: totalDurationOfEmploymentInMonths,
        isRetired: isRetired
      }
      this.borrowerEmploymentInfoByBorrowerId.set(borrower.borrowerId, borrowerEmployemntInfo);
    })
  }

  public hasIncome(borrowerId: number): boolean {
    if (this.borrowerIncomeComponents) {
      const borrowerIncomeComponent = this.borrowerIncomeComponents.find(c => c.borrower.borrowerId == borrowerId);
      if (borrowerIncomeComponent) {
        return borrowerIncomeComponent.incomes.length > 0;
      }
    }
    return false;
  }

  private calculateEmploymentDurationInMonths = (employment: Employment): number => {
    if (!employment.startDate) {
      return 0;
    }
    let endDate = DateTime.now();
    const startDate = DateTime.fromFormat(employment.startDate, this._dateFormat);
    if (employment.employmentType === EmploymentTypeEnum.FormerEmployer) {
      if (!employment.endDate) {
        return 0;
      }
      endDate = DateTime.fromFormat(employment.endDate, this._dateFormat);
    }
    const diff = endDate.diff(startDate, ["months"]);
    return Math.round(diff.months);
  }
}

export class BorrowerEmploymentInfo {
  isRetired: boolean = false;
  numberOfMonthsEmployed: number;
}

