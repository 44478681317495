<ng-template #createMultipleBorrowers>
  <div class="row mb-10">
    <div class="col-md-12 fv-row mb-5" *ngFor="let coBorrower of coBorrowers; let i = index">
      <div class="card card-flush shadow-sm">
        <div class="card-header mh-30">
          <h3 class="card-title">{{coBorrower.firstName + " " + coBorrower.lastName}}</h3>
        </div>
        <div class="card-body py-2">
          <borrower-info-mini #borrowerInfo [borrower]="coBorrower"
            [borrowerSettings]="borrowerSettings"></borrower-info-mini>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<wizard-step-template [stepMainTemplate]="createMultipleBorrowers" [step]="step"
  [stepComponent]="this"></wizard-step-template>
