import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { WizardStepComponentBase } from '../wizard-step-base.component';
import { PurchaseCreditsStep } from '../../../models/wizard/purchase-credits-step.model';
import { NgForm } from '@angular/forms';
import { TransactionDetail } from '../../../models/transaction-detail.model';
import { PurchaseCredit } from "../../../models/purchase-credit.model";
import { MortgageApplicationExtension } from '../../../models/mortgage-app.model';
import { SubjectProperty } from '../../../models/subject-property.model';
import { Address } from '../../../models/address-model';
import { EnumerationItem } from 'projects/shared/models/enumeration-item.model';
import { EnumsService } from '../../../services/enums.service';
import { AddressComponent, AddressFieldConfigSettings } from '../../address/address.component';
import { UtilsService } from '../../../services/utils.service';
import { BorrowerStepStatus } from '../../../models/borrower-step-status.model';
import { MortgageApplicationService } from '../../../services/mortgage-application.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'purchase-credits-step',
  templateUrl: 'purchase-credits-step.component.html',
})
export class PurchaseCreditsStepComponent
  extends WizardStepComponentBase<PurchaseCreditsStep>
  implements OnInit, AfterViewInit {

  @ViewChild('purchaseCreditsForm')
  purchaseCreditsForm: NgForm | undefined;

  @ViewChild('propertyAddress')
  subjectPropertyAddressComponent: AddressComponent | undefined;

  hasPurchaseAgreement: boolean | undefined | null;

  haveSellerCredits: boolean | null | undefined = null;

  sellerCredits: number | undefined = undefined;

  earnestMoneyDeposit: number | undefined = undefined;

  purchaseAggreementDate: string | undefined | null = undefined;

  expectedCloseOfEscrowDate: string | undefined | null = undefined;

  subjectPropertyAddress: Address | undefined | null;

  subjectProperty: SubjectProperty | undefined | null;

  occupancyTypes: EnumerationItem[] = [];

  protected availableStates: EnumerationItem[] = null;

  protected isSubjectPropertyAddressTbd: boolean = false;

  fieldConfigSettingsForAddress: AddressFieldConfigSettings = {
    stateField: 'subjectProperty.state'
  }

  constructor(private readonly _injector: Injector,
    private readonly _utilsService: UtilsService,
    private readonly _enumsService: EnumsService,
    private readonly _mortgageApplicationService: MortgageApplicationService) {
    super(_injector);
    if (this.mortgageApplication.subjectProperty) {
      const isRefinance = this.mortgageApplication.subjectProperty.purposeOfLoan === "Refinance";
      if (isRefinance) {
        if (this.cameHereWithBackNavigation) {
          this.wizardFlowService.navigateBackward();
        } else {
          this.wizardFlowService.navigateForward();
        }
        return;
      }
    }
    this.occupancyTypes = this._enumsService.usageTypes;
    this.saveMortgageApplicationBeforeNextStep = true;
    if (!this.mortgageApplication.transactionDetail) {
      this.mortgageApplication.transactionDetail = new TransactionDetail();
    }
    if (!this.mortgageApplication.extension) {
      this.mortgageApplication.extension = new MortgageApplicationExtension();
    }
    if (!this.mortgageApplication.subjectProperty) {
      this.mortgageApplication.subjectProperty = new SubjectProperty();
    }
    this.subjectProperty = this.mortgageApplication.subjectProperty;
    this.subjectPropertyAddress = new Address();
    this.subjectPropertyAddress.address1 = this.mortgageApplication.subjectProperty.address1;
    this.subjectPropertyAddress.city = this.mortgageApplication.subjectProperty.city;
    this.subjectPropertyAddress.state = this.mortgageApplication.subjectProperty.state;
    this.subjectPropertyAddress.zipCode = this.mortgageApplication.subjectProperty.zipCode;
    this.subjectPropertyAddress.county = this.mortgageApplication.subjectProperty.county;
    this.mortgageApplication.subjectProperty.presentValue = this.mortgageApplication.subjectProperty.presentValue || null;
    this.subjectPropertyAddress.presentValue = this.mortgageApplication.subjectProperty.presentValue || null;

    this.hasPurchaseAgreement =
      !!this.mortgageApplication.extension.hasSignedPurchaseAgreement;
    if (this.hasPurchaseAgreement) {
      this.initializeContractAndCloseOfEscrowDates();
      this.initializeSellerCreditsAndEarnestMoneyDepositValues();
    }
  }

  async ngOnInit(): Promise<void> {
    const allStates = this._enumsService.states;
    if (this.step.restrictStatesToLicenseStates) {
      const licenseStates = await firstValueFrom(this._mortgageApplicationService.getLicenseStates(this.mortgageApplication.applicationId));
      if (licenseStates && licenseStates.length > 0) {
        this.availableStates = allStates.filter(s => licenseStates.map(ls => ls.toLowerCase()).includes(s.value.toLowerCase()));
      }
    } else {
      this.availableStates = allStates;
    }
    if (this.step.autoSelectLookingToGetPreApproved) {
      this.onLookingForPreApprovalSelected();
    }
  }

  ngAfterViewInit(): void {
    let borrowerStepStatus = this.wizardFlowService.context.borrowerAppStatus.details.borrowerStepStatuses.find(b => b.borrowerId ==
      this.currentBorrower.borrowerId);
    if (borrowerStepStatus && borrowerStepStatus.stepStatusValues) {
      const statusForStep = borrowerStepStatus.stepStatusValues[this.step.id];
      if (statusForStep) {
        this.subjectPropertyAddressComponent.isZipKnownByTheBorrower = statusForStep['borrowerKnowsTheZipCodeWhenSubjectPropertyAddressIsTbd'];
      }
    }
    if (this.subjectPropertyAddressComponent) {
      if (this.step.autoSelectTbdForSubjectPropertyAddress) {
        this.subjectPropertyAddressComponent.isTbd = true;
        this.subjectPropertyAddressComponent.isTbdDisabled = this.subjectPropertyAddressComponent.isTbd;
      }
      this.isSubjectPropertyAddressTbd = this.subjectPropertyAddressComponent.isTbd;
    }
  }

  onSubjectPropertyAddressIsTbdChanged = (isTbd: boolean) => {
    this.isSubjectPropertyAddressTbd = isTbd;
  }

  onAutoSelectTbdForSubjectPropertyAddressChanged = () => {
    if (this.step.autoSelectTbdForSubjectPropertyAddress) {
      this.subjectPropertyAddressComponent.isTbd = this.step.autoSelectTbdForSubjectPropertyAddress;
    }
    this.subjectPropertyAddressComponent.isTbdDisabled = this.step.autoSelectTbdForSubjectPropertyAddress;
  }

  onNextClicked() {
    if (this.purchaseCreditsForm) {
      this.purchaseCreditsForm.form.markAllAsTouched();
      const isAddressvalid = this.subjectPropertyAddressComponent.validate();
      if (this.purchaseCreditsForm.form.valid && isAddressvalid) {
        this.persistTheChoiceForBorrowerKnowsTheZipCodeWhenSubjectPropertyAddressIsTbd();
        this.mortgageApplication.extension.hasSignedPurchaseAgreement = this.hasPurchaseAgreement;
        if (this.hasPurchaseAgreement) {
          this.setContractAndCloseOfEscrowDatesOnMortgage();
          this.addSellerCreditsAndEarnestMoneyDepositToMortgage();
        } else {
          this.removeSellerCreditsAndEarnestMoneyDepositFromMortgage();
        }
        if (!this.haveSellerCredits) {
          this.removeSellerCreditsFromMortgage();
        }
        this.mortgageApplicationService.setSubjectProperty(this.subjectPropertyAddress);
        super.onNextClicked();
      }
    }
  }

  onIsInPurchaseAggrementSelected = () => {
    this.hasPurchaseAgreement = true;
    if (this.subjectPropertyAddress?.presentValue) {
      this.subjectProperty.presentValue = this.subjectPropertyAddress.presentValue;
    }
  };

  onLookingForPreApprovalSelected = () => {
    this.hasPurchaseAgreement = false;
    this.haveSellerCredits = false;
    this.sellerCredits = undefined;
    this.earnestMoneyDeposit = undefined;
    this.purchaseAggreementDate = undefined;
    this.expectedCloseOfEscrowDate = undefined;
    if (this.subjectProperty?.presentValue) {
      this.subjectPropertyAddress.presentValue = this.subjectProperty.presentValue;
    }
  };

  onSubjectPropertyAddressTbdLabelChanged = (newLabel: string) => {
    this.step.subjectPropertyAddressTbdLabel = newLabel;
  }

  onHaveSellerCreditsQuestionAnswered = () => {
    if (!this.haveSellerCredits) {
      this.sellerCredits = undefined;
    }
  };

  onSellerCreditsChanged = () => {
    if (!this.sellerCredits || Number(this.sellerCredits) === 0) {
      this.sellerCredits = undefined;
    }
  };

  private persistTheChoiceForBorrowerKnowsTheZipCodeWhenSubjectPropertyAddressIsTbd = () => {
    let borrowerStepStatus = this.wizardFlowService.context.borrowerAppStatus.details.borrowerStepStatuses.find(b => b.borrowerId ==
      this.currentBorrower.borrowerId);
    if (!borrowerStepStatus) {
      borrowerStepStatus = new BorrowerStepStatus(this.wizardFlowService.context.currentBorrower.borrowerId!);
      this.wizardFlowService.context.borrowerAppStatus.details.borrowerStepStatuses.push(borrowerStepStatus);
    }
    if (!borrowerStepStatus.stepStatusValues) {
      borrowerStepStatus.stepStatusValues = {};
    }
    borrowerStepStatus.stepStatusValues[this.step.id] = {};
    borrowerStepStatus.stepStatusValues[this.step.id]['borrowerKnowsTheZipCodeWhenSubjectPropertyAddressIsTbd'] = this.subjectPropertyAddressComponent.isZipKnownByTheBorrower;
  }

  private setContractAndCloseOfEscrowDatesOnMortgage = () => {
    this.mortgageApplication.extension.purchaseAgreementContractSignedDate = this._utilsService.formatISODate(this.purchaseAggreementDate as string);
    this.mortgageApplication.extension.purchaseAgreementExpectedCOEDate = this._utilsService.formatISODate(this.expectedCloseOfEscrowDate as string);
  };

  private initializeContractAndCloseOfEscrowDates = () => {
    if (this.mortgageApplication.extension.purchaseAgreementContractSignedDate) {
      this.purchaseAggreementDate = this._utilsService.fromISOFormatToLocal(this.mortgageApplication.extension.purchaseAgreementContractSignedDate);
    }
    if (this.mortgageApplication.extension.purchaseAgreementExpectedCOEDate) {
      this.expectedCloseOfEscrowDate = this._utilsService.fromISOFormatToLocal(this.mortgageApplication.extension.purchaseAgreementExpectedCOEDate);
    }
  };

  private initializeSellerCreditsAndEarnestMoneyDepositValues = () => {
    const sellerCreditIndex =
      this.mortgageApplication.transactionDetail.purchaseCredits.findIndex(
        (pc) => pc.purchaseCreditType === 'SellerCredit'
      );
    this.haveSellerCredits = sellerCreditIndex >= 0;
    if (sellerCreditIndex >= 0) {
      this.sellerCredits =
        this.mortgageApplication.transactionDetail.purchaseCredits[
          sellerCreditIndex
        ].purchaseCreditAmount;
    }
    const emdIndex =
      this.mortgageApplication.transactionDetail.purchaseCredits.findIndex(
        (pc) => pc.purchaseCreditType === 'DepositOnSalesContract'
      );
    if (emdIndex >= 0) {
      this.earnestMoneyDeposit =
        this.mortgageApplication.transactionDetail.purchaseCredits[
          emdIndex
        ].purchaseCreditAmount;
    }
  };

  private removeSellerCreditsFromMortgage = () => {
    const sellerCreditIndex =
      this.mortgageApplication.transactionDetail.purchaseCredits.findIndex(
        (pc) => pc.purchaseCreditType === 'SellerCredit'
      );
    if (sellerCreditIndex >= 0) {
      this.mortgageApplication.transactionDetail.purchaseCredits.splice(
        sellerCreditIndex,
        1
      );
    }
  };

  private removeSellerCreditsAndEarnestMoneyDepositFromMortgage = () => {
    this.removeSellerCreditsFromMortgage();
    const emdIndex =
      this.mortgageApplication.transactionDetail.purchaseCredits.findIndex(
        (pc) => pc.purchaseCreditType === 'DepositOnSalesContract'
      );
    if (emdIndex >= 0) {
      this.mortgageApplication.transactionDetail.purchaseCredits.splice(
        emdIndex,
        1
      );
    }
  };

  private addSellerCreditsAndEarnestMoneyDepositToMortgage = () => {
    const sellerCredit = new PurchaseCredit();
    sellerCredit.purchaseCreditAmount = Number(this.sellerCredits);
    sellerCredit.purchaseCreditType = 'SellerCredit';

    const earnestMoneyDeposit = new PurchaseCredit();
    earnestMoneyDeposit.purchaseCreditAmount = Number(this.earnestMoneyDeposit);
    earnestMoneyDeposit.purchaseCreditType = 'DepositOnSalesContract';

    const sellerCreditIndex =
      this.mortgageApplication.transactionDetail.purchaseCredits.findIndex(
        (pc) => pc.purchaseCreditType === 'SellerCredit'
      );
    if (sellerCreditIndex >= 0) {
      this.mortgageApplication.transactionDetail.purchaseCredits[
        sellerCreditIndex
      ] = sellerCredit;
    } else {
      this.mortgageApplication.transactionDetail.purchaseCredits.push(
        sellerCredit
      );
    }
    const emdIndex =
      this.mortgageApplication.transactionDetail.purchaseCredits.findIndex(
        (pc) => pc.purchaseCreditType === 'DepositOnSalesContract'
      );
    if (emdIndex >= 0) {
      this.mortgageApplication.transactionDetail.purchaseCredits[emdIndex] =
        earnestMoneyDeposit;
    } else {
      this.mortgageApplication.transactionDetail.purchaseCredits.push(
        earnestMoneyDeposit
      );
    }
  };
}
